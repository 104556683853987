import { defu } from 'defu';
import translations from '~/translations';

const base = {
  wb: {
    //@todo add ao winx-base
    viewMore: 'See all',
    _action: {
      back: 'Back',
    },
  },
  base: {
    // Traduções específicas do projeto
    _research: {
      lastResearch: 'Latest Surveys',
      employees: 'Employees',
      totalEmployees: 'Total Employees',
      respondents: 'Respondents',
      participation: 'Participation',
      participationGeneral: 'General Participation',
      info: 'Information',
      favorability: 'Favorability',
      card: `
        Welcome to the Winx dashboard! Easily navigate detailed survey data on employee engagement and company culture at {companyName}.
        Explore various metrics and gain deep insights into the work environment and employee behaviors.
      `,
      listCard: `Explore the full collection of surveys focused on employee engagement and organizational culture. Dive into detailed analysis and gain valuable insights that can transform your company's dynamics and environment.`,
      _viewMore: {
        onGoing: 'You have an ongoing survey:',
        remember: 'Remind your employees to answer the form.',
        belowList: ', below is a list of all surveys.',
        list: 'Survey List',
        noResearch: 'There are no ongoing surveys.',
      },
    },
    _area: {
      _box: {
        respondents: 'Respondents',
        participation: 'Participation',
        date: 'Date',
      },
    },
    _status: {
      finished: 'Finished',
      finishedIn: 'Finished on ',
      inProgress: 'In Progress',
      notStarted: 'Not started',
    },
    _conjunction: {
      from: ' from ',
      until: ' until ',
      and: ' and ',
      of: ' Of ',
      to: ' to ',
    },
    hello: 'Hello',
    infoData: 'Informative data',
    _ranking: {
      agree: 'Totally agree',
      partiallyAgree: 'Partially agree',
      neutral: 'Neutral',
      partiallyDisagree: 'Partially disagree',
      disagree: 'Totally disagree',
      na: 'Not applicable',
    },
    _tipWinnie: {
      title: `Winnie's Tip`,
      message:
        'The table below shows the percentage of\n' +
        'favorability for each of the questions\n' +
        'that make up the analyzed index. To reorder the\n' +
        'results, simply click on the column headers.',
    },
    _buttons: {
      search: 'My Searches',
      export: 'Export',
      exportFile: 'Export .xlsx',
      exportIndexByFilter: 'Export Index by Filters',
      filter: 'Filter',
      activeFilter: 'Active Filters',
      reload: 'Clear',
      progress: 'Progress',
      chart: 'Chart',
      start: 'Home',
      apply: 'Apply',
      remove: 'Remove',
      select: 'Select',
    },
    _titles: {
      infoData: 'Informative data',
      percentagePoints: 'The data may have a variation of 1 p.p.',
      _select: {
        filter: 'Select a filter.',
        question: 'Select a question',
        _company: {
          search: 'Search company',
          title: 'Companies',
        },
        search: 'Search for an answer here',
      },
      _filters: {
        filter: 'Filters',
        search: 'Search filter',
        noShowing: 'Nothing to show',
        checklist: 'There is no checklist to show here for now.',
        applied: 'Filters Applied',
      },
      _tables: {
        _header: {
          filter: 'Participation by Filter',
          evolution: 'Participation Evolution',
          distribution: 'Distribution by Filter',
          cluster: 'Clusters by Filter',
          anchor: 'Anchor questions',
          culture: 'Questions - Perceived Culture',
          flow: 'Flow by Filter',
          question: 'Ranking by Question',
          currentValues: 'Current Values',
        },
        _columns: {
          description: 'Description',
          fullyEngaged: 'Fully Engaged',
          satisfied: 'Satisfied',
          undecided: 'Undecided',
          highTurnoverPotential: 'High Turnover Potential',
          insufficientData: 'Insufficient Data',
          validData: 'Valid Data',
          questions: 'Questions',
          favorable: 'Favorable',
          neutral: 'Neutral',
          unfavorable: 'Unfavorable',
          values: 'Values',
          cultures: 'Cultures',
        },
      },
      dimensions: 'Dimensions',
    },
    _insufficientData: {
      title: 'Insufficient Data',
      card: 'Currently, this page does not have enough data to display information. Check back soon to see the results!',
    },
    _insufficientFilter: {
      title: 'Filters with insufficient data',
      description:
        'Oops, the filter selection you chose has less than 5 respondents. Please adjust your filters and try again!',
      button: 'Clean the filters',
    },
  },
  titleBase: 'Winx Dashboard',
  _pages: {
    _login: {
      aid: 'Sponsor',
      _step1: {
        title: 'Access by @:{option}',
        subtitleOtp: 'Enter your work @:{option} to receive a one-time access code.',
        subtitle: 'Enter your document to access.',
        placeholder: 'Work @:wb.email',
      },
      _step2: {
        title: 'Verification Code',
        subtitle: 'We sent a 6-digit code to your work @:{option}.',
        resendCode: "Didn't receive the code?",
        resendByEmail: 'Send new code by email',
        resendByPhone: 'Send new code by SMS',
      },
      changeOfAccess: 'Not the correct access method?',
      support: "If you can't find our message with the code, check your SPAM folder, it might be there.",
      comments: 'If you need help, just contact our {helpCenter}.',
      footer: 'Raising awareness for authentic happiness.',
      title: 'Unified access',
      resendingAccess: 'A new access code has been sent to your @:{channel}.',
    },
    _research: {},
  },

  // @todo bk das traduções anteriores, remover ao transferir para novo padrão
  and: ' and ',
  hello: 'Hello',
  options: 'Options',
  welcome: {
    intro: "On this page, you'll find important information about the surveys of the company",
    onGoing: 'You have an ongoing survey:',
    remember: 'Remind your collaborators to answer the form.',
    de: 'of',
  },
  resumeInfo: {
    default:
      "On  this page, you'll get an overview of the main insights from the most recent survey carried out with your employees.​",
    resume:
      "On  this page, you'll get an overview of the main insights from the most recent survey carried out with your employees.​",
    magic:
      "Here you can gain insight into the 5 keys to MAGIC (meaning, autonomy, growth, impact, and connection) based on your employees' responses​​",
    engagementClusters:
      'In this part, you will be able to understand to what extent employees at your company are engaged.​',
    engagementDimensions:
      "On this page, you will find out where most of your company's employee engagement comes from.​",
    rankingByFilters:
      "On this page, you will see what your company employees indicated on the scale. Just choose a question and we'll show you the numbers for each alternative.​",
    rankingByQuestion:
      'On this page, you will see what employees at your company indicated on the scale in order according to the chosen status.',
    adhesion: 'On this page, you will find the participation rate per day and sector for your company.​​',
    strengthAndHealthiness:
      'On this page, you will see how your employees feel about the strength and health of the company culture.',
    cultureTypes:
      'On this page, you will understand the difference between the desired culture and the culture perceived by the company employees.​',
    cultureElements:
      "Here, you'll find out how employees are aligned with the vision, purpose, values, and mission of the company.​",
    dissonance: 'Here, you will understand how culture and current values are according to each type of culture.',
    valueRanking: "Here, you'll see how employees view the company's current and desired values through a ranking.",
    openQuestions:
      "On this page, you'll be able to get more complete and specific insights with the answers to open questions.",
  },
  boosterh: {
    card: 'Boosterh will guide you in your next steps, like a consultant who will support you to be more aware and competent in your challenges. Boosterh analyzes the results of each response to the questionnaire applied in your company and offers recommendations for increasing engagement and high performance. While the employee receives their own recommendations, on an individual and confidential basis, Boosterh offers HR a general scenario of how the climate and engagement can be improved.',
    pages: {
      hr: 'For HR',
      leader: 'For Leaders',
      employee: 'For Employees',
    },
    recommendation: {
      hr: 'Recommendations - HR',
      leader: 'Recommendations - Leaders',
      employee: 'Recommendations - Employees',
    },
  },
  importantData: 'Below is a summary with some of the important data of the company',
  helpCentral: 'Help Central',
  menus: {
    search: {
      mySearchs: 'My Surveys',
      hello: 'Hello Winner,',
      companySearch: 'Bellow you can find all the surveys for this organization',
      access: 'Access survey details',
    },
    filter: {
      title: 'Demographics',
      combine: 'Combine demographics to refine results.',
      clean: 'Clear',
      apply: 'Apply',
    },
    header: {
      home: 'Home',
      surveys: 'Surveys',
    },
  },
  pages: {
    dashboard: 'Dashboard',
    participationGeneral: 'Overall Participation',
    buttons: {
      mySearchs: 'My Surveys',
      indexByFilter: 'Index By Demographic',
      export: 'Export',
      filter: 'Filter',
      answers: 'Answers',
      index: 'Index',
    },
    resume: {
      overallFavorability: 'Overall Favorability',
      contributors: 'Contributors',
      respondents: 'Respondents',
      participationPercentage: 'Participation Percentage',
    },
    general: {
      details: 'Details',
      participation: 'Participation',
      favorability: 'Overall Favorable Score',
      rankingByFilters: 'Ranking By Demographic',
      rankingByQuestions: 'Ranking By Items',
      questionRanking: 'Ranking By Item',
      dailyParticipation: 'Daily Participation',
    },
    select: {
      select: 'Select.vue an option to filter the data from table bellow',
      chooseQuestion: 'Select.vue an item to filter the data from chart and table',
      searchAnswers: 'Campo para buscar respostas.',
      searchAnswer: 'Campo para buscar uma resposta da lista.',
      filterByQuestion: 'Filter By Item',
      searchResponse: 'Search for a response here.',
      responseList: 'Response list',
      choose: 'Choose an item',
    },
    tables: {
      titles: {
        questionRanking: 'Ranking By Item',
        moreFavorable: 'Highest Scoring Items',
        lessFavorable: 'Lowest Scoring Items',
      },
      header: {
        participationEvolution: 'Participation by Date',
        filterDistribution: 'Distribution By Demographic',
        clustersByFilter: 'Clusters By Demographic',
        anchorQuestions: 'Anchor Questions',
        participationByFilter: 'Participation By Demographic',
        questionsCulture: 'Perceived Culture Items',
        flowByFilter: 'Flow by Demographic',
      },
      columns: {
        description: 'Description',
        fullyEngaged: 'Fully Engaged',
        keyContributors: 'Key Contibutors',
        opportunityGroup: 'Opportunity Group',
        fullyDisengaged: 'Fully Disengaged',
        satisfied: 'Content',
        undecided: 'Undecided',
        highTurnoverPotential: 'High Potential For Turnover',
        favorable: 'Favorable',
        filter: 'Demographic',
        neutral: 'Neutrals',
        promoters: 'Promoters',
        detractors: 'Detractors',
        unfavorable: 'Unfavorable',
        delta: 'Delta',
        participation: 'Participation',
        name: 'Name',
        votes: 'Times Selected',
        daily: 'Date',
        grossValue: 'Gross Value',
        percentage: 'Percentage',
        values: 'Values',
        cultures: 'Cultures',
        yes: 'Yes',
        no: 'No',
        NA: 'Not applicable',
        innovateAndEntrepreneur: 'Innovate and Enterprise',
        sellAndCompete: 'Sell and Compete',
        planAndControl: 'Plan and Control',
        collaborateAndCoCreate: 'Collaborate and Co-create',
        questions: 'Items',
        favorability: 'Favorability',
        inFlow: 'In Flow',
        enteringFlow: 'Entering Flow',
        outFlow: 'Out Flow',
      },
      row: {
        insufficientData: 'Insufficient Data',
      },
    },
    graph: {
      totalResponses: 'Total Responses',
      keywords: 'Key Words',
      desiredCulture: 'Desired Culture',
      perceivedCulture: 'Perceived Culture',
      currentCulture: 'Current Culture',
      cultureElements: 'Culture Elements',
      rankingByFilters: 'Ranking By Demographic',
      favorability: 'Favorability',
      participation: 'Participation',
    },
    engagement: {
      titles: {
        title: 'Engagement Survey',
        summary: 'Summary',
        magic: 'MAGIC®',
        engagementClusters: 'Engagement Clusters',
        attritionClusters: 'Attrition Clusters',
        eNPS: 'eNPS',
        flow: 'Flow',
        engagementDimensions: 'Dimensions of Engagement',
        influencers: 'Influencers',
        openQuestions: 'Comments',
        rankingByFilters: 'Ranking By Demographic',
        rankingByQuestions: 'Ranking By Items',
        participation: 'Participation',
        customQuestions: 'Custom Items',
      },
      influencers: {
        top5Influencers: 'Top 5 Influencers',
        searchInfluencer: 'Campo para buscar um influenciador.',
        searchInfluencerHere: 'Search for an influencer here.',
        influencerList: 'List of Influencers',
        influencersAndChangeAgents: 'Influencers | Change Agents',
        influencersAndExperts: 'Influencers | Experts',
        influencersAndMentors: 'Influencers | Mentors',
        changeAgents: 'Change Agents',
        mentors: 'Mentors',
        experts: 'Experts',
      },
    },
    culture: {
      titles: {
        summary: 'Summary',
        strengthAndHealthiness: 'Strengh and Healthiness',
        cultureTypes: 'Culture Types',
        cultureElements: 'Culture Elements',
        dissonance: 'Dissonance',
        efetivity: 'Effective Culture Score',
        valueRanking: 'Value Ranking',
        openQuestions: 'Comments',
        rankingByFilters: 'Ranking By Demographic',
        rankingByQuestions: 'Ranking By Items',
        participation: 'Participation',
      },
      values: {
        currentValues: 'Current Values',
        desiredValues: 'Desired Values',
        centralValue: 'Core Value',
      },
    },
    family: {
      titles: {
        familyDimensions: 'Dimensions',
      },
    },
  },
};
export default defineI18nLocale(async () => {
  return defu(base, translations('en'));
});
