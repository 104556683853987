const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-blue-300 mb-6 lg:mb-0 font-batusa">
      Jornada do Colaborador
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    A Jornada do Colaborador indica aspectos fundamentais que definem cada etapa do percurso do colaborador, desde a admissão até o desligamento. Com foco em recompensa, treinamento, dentre outros, mostramos como esses elementos são essenciais para promover um ambiente de trabalho
    <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">engajador e produtivo</span>.
    Navegue e visualize como as práticas estratégicas transformam a experiência dos colaboradores em cada fase de sua trajetória na empresa.
    </p>
  `,
  cardResume: `
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    A Jornada do Colaborador indica aspectos fundamentais que definem cada etapa do percurso do colaborador, desde a admissão até o desligamento. Com foco em recompensa, treinamento, dentre outros, mostramos como esses elementos são essenciais para promover um ambiente de trabalho
    <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">engajador e produtivo</span>.
    Navegue e visualize como as práticas estratégicas transformam a experiência dos colaboradores em cada fase de sua trajetória na empresa.
    </p>
  `,
  title: 'Jornada do Colaborador',
  subTitle: 'Lista de Perguntas',
  label: 'Dimensão',
  _tables: {
    reward: 'Recompensa',
    development: 'Desenvolvimento',
    admission: 'Admissão',
    integration: 'Integração',
    career: 'Carreira',
    performanceevaluation: `Avaliação de Desempenho`,
    evaluation: `Avaliação de Desempenho`,
    training: 'Treinamento',
    communication: 'Comunicação',
    resignation: 'Renúncia',
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-blue-300 mb-6 lg:mb-0 font-batusa">
      Jornada del Colaborador
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    El Recorrido del Empleado describe los aspectos clave que definen cada etapa de la experiencia de un empleado, desde la contratación hasta la renuncia. Con un enfoque en las recompensas, la capacitación y más, mostramos cómo estos elementos son esenciales para fomentar un entorno de trabajo
    <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">atractivo y productivo</span>.
    Explore y vea cómo las prácticas estratégicas transforman la experiencia del empleado en cada etapa de su recorrido con la empresa.
    <p/>
  `,
  cardResume: `
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    El Recorrido del Empleado describe los aspectos clave que definen cada etapa de la experiencia de un empleado, desde la contratación hasta la renuncia. Con un enfoque en las recompensas, la capacitación y más, mostramos cómo estos elementos son esenciales para fomentar un entorno de trabajo
    <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">atractivo y productivo</span>.
    Explore y vea cómo las prácticas estratégicas transforman la experiencia del empleado en cada etapa de su recorrido con la empresa.
    <p/>
  `,
  title: 'Jornada del Colaborador',
  subTitle: 'Lista de Preguntas',
  label: 'Dimensión',
  _tables: {
    reward: 'Recompensa',
    development: 'Desarrollo',
    admission: 'Admisión',
    integration: 'Integración',
    career: 'Carrera',
    performanceevaluation: `Evaluación de Desempeño`,
    evaluation: `Evaluación de Desempeño`,
    training: 'Entrenamiento',
    communication: 'Comunicación',
    resignation: 'Renuncia',
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-blue-300 mb-6 lg:mb-0 font-batusa">
      Journey of the Employee
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    The Employee Journey outlines key aspects that define each stage of an employee’s experience, from hiring to resignation. With a focus on rewards, training, and more, we show how these elements are essential to fostering an
    <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">engaging and productive</span> work environment.
    Browse and see how strategic practices transform the employee experience at each stage of their journey with the company.
    </p>
  `,
  cardResume: `
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    The Employee Journey outlines key aspects that define each stage of an employee’s experience, from hiring to resignation. With a focus on rewards, training, and more, we show how these elements are essential to fostering an
    <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">engaging and productive</span> work environment.
    Browse and see how strategic practices transform the employee experience at each stage of their journey with the company.
    </p>
  `,
  title: 'Journey of the Employee',
  subTitle: 'List of Questions',
  label: 'Dimension',
  _tables: {
    reward: 'Reward',
    development: 'Development',
    admission: 'Admission',
    integration: 'Integration',
    career: 'Career',
    performanceevaluation: `Performance Evaluation`,
    evaluation: `Performance Evaluation`,
    training: 'Training',
    communication: 'Communication',
    resignation: 'Resignation',
  },
};

export default {
  key: 'experienceDimension',
  translations: {
    ptBr,
    es,
    en,
  },
};
