const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-yellow-400 text-center mb-6 lg:mb-0 font-batusa">
      Perguntas Abertas
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Aqui, apresentamos as respostas das
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">perguntas abertas</span>,
      em que os colaboradores compartilharam suas opiniões e sugestões detalhadas. Essas perguntas capturam insights valiosos para melhorias contínuas. As
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">respostas abertas</span>
      são fundamentais porque permitem uma compreensão mais genuína e profunda das percepções e necessidades dos colaboradores, guiando ações estratégicas.
    </p>`,
  _buttons: {
    update: 'Quero ver meus Destaques',
  },
  _questions: {
    title: `Perguntas Abertas`,
    subTitle: `Lista de respostas`,
    label: `respostas`,
    answers: 'Respostas',
  },
  _answers: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
        Respostas
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        As respostas abertas apresentam as
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">opiniões</span> e
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">expectativas</span>
        dos colaboradores. Veja abaixo as respostas que os colaboradores escreveram para a pergunta selecionada.
      </p>`,
    familyCard: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
        Respostas
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        As respostas abertas apresentam as
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">opiniões</span> e
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">expectativas</span>
        dos familiares dos colaboradores. Veja abaixo as respostas que os familiares escreveram para a pergunta selecionada.
      </p>
    `,
  },
  _highlight: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-teal-400 text-center mb-6 lg:mb-0 font-batusa">
        Destaques da Winnie
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        Vou extrair os <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">temas principais</span>
        das respostas abertas dos seus colaboradores para que você compreenda os
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">tópicos mais recorrentes</span>
        e acelere o planejamento de ações de melhoria. Escolha uma pergunta e veja os destaques que selecionei!
      </p>`,
    request: 'Solicitação',
    complaint: 'Reclamação',
    problem: 'Problema',
    compliment: 'Elogio',
    others: 'Outros',
    search: 'Os Destaques estão carregando, aguarde um momento...',
    title: 'Destaques da Winnie',
  },
  _message: {
    title: 'Estou trabalhando nos seus destaques!',
    subtitle: 'Quando eu acabar, você receberá uma notificação por email. Aguarde um momento!',
    _insufficient: {
      title: 'Ops, o valor mínimo de comentários ainda não foi atingido.',
      subtitle: 'Volte em breve para conferir os resultados!',
    },
  },
  word: `Palavra-chave`,
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-yellow-400 text-center mb-6 lg:mb-0 font-batusa">
      Preguntas Abiertas
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Aquí presentamos las respuestas a las
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">preguntas abiertas</span>,
      en las que los colaboradores compartieron sus opiniones y sugerencias detalladas. Estas preguntas capturan valiosos conocimientos para mejoras continuas. Las
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">respuestas abiertas</span>
      son fundamentales porque permiten una comprensión más genuina y profunda de las percepciones y necesidades de los colaboradores, guiando acciones estratégicas.
    </p>`,
  _buttons: {
    update: '¡Quiero ver mis Destacados',
  },
  _questions: {
    title: `Preguntas Abiertas`,
    subTitle: `Lista de Respuestas`,
    label: `respuestas`,
    answers: 'Respuestas',
  },
  _answers: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
        Respuestas
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        Las respuestas abiertas presentan las
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">opiniones</span> y
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">expectativas</span>
        de los empleados. Vea a continuación las respuestas que los contribuyentes escribieron a la pregunta seleccionada.
      </p>`,
    familyCard: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
        Respuestas
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        Las respuestas abiertas presentan las
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">opiniones</span> y
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">expectativas</span>
        de los familiares de los empleados. Vea a continuación las respuestas que escribieron los familiares para la pregunta seleccionada.
      </p>`,
  },
  _highlight: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-teal-400 text-center mb-6 lg:mb-0 font-batusa">
        Lo más destacado de Winnie
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        Voy a extraer los <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">temas principales</span>
        de las respuestas abiertas de sus colaboradores, permitiéndole comprender los
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">temas más recurrentes</span>
        y acelerar la planificación de acciones de mejora. ¡Elija una pregunta y vea los aspectos destacados que he seleccionado!
      </p>`,
    request: 'Solicitud',
    complaint: 'Queja',
    problem: 'Problema',
    others: 'Otros',
    search: 'Buscando Destacados, espere un momento...',
    title: 'Destacados de Winnie',
  },
  _message: {
    title: 'Estoy trabajando en tus aspectos más destacados!',
    subtitle: 'Cuando termine, recibirás una notificación por correo electrónico. ¡Espera un momento!',
    _insufficient: {
      title: 'Vaya, aún no se ha alcanzado el número mínimo de comentarios.',
      subtitle: '¡Vuelve pronto para comprobar los resultados!',
    },
  },
  word: `Palabra Clave`,
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-yellow-400 text-center mb-6 lg:mb-0 font-batusa">
      Open Questions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Here, we present the answers to the
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">open-ended questions</span>,
      where the collaborators shared their detailed opinions and suggestions. These questions capture valuable insights for continuous improvement. The
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">open-ended responses</span>
      are fundamental because they allow a more genuine and deep understanding of the collaborators' perceptions and needs, guiding strategic actions.
    </p>`,
  _buttons: {
    update: 'I want to see my Highlights',
  },
  _questions: {
    title: `Open Questions`,
    subTitle: `List of Answers`,
    label: `responses`,
    answers: 'Answers',
  },
  _answers: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
        Answers
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        Open-ended answers present the
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">opinions</span> and
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">expectations</span>
        of employees. See below the responses that employees wrote to the selected question.
      </p>`,
    familyCard: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
        Answers
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        Open-ended answers present the
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">opinions</span> and
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">expectations</span>
        of the employees' family members. See below the responses that the family members wrote for the selected question.
      </p>`,
  },
  _highlight: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-teal-400 text-center mb-6 lg:mb-0 font-batusa">
        Winnie's Highlights
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
        I will extract the <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">main themes</span>
        from your employees' open-ended responses to understand the
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">most recurring topics</span>
        and accelerate the planning of improvement actions. Choose a question and see the highlights I've selected!
      </p>`,
    request: 'Request',
    complaint: 'Complaint',
    problem: 'Problem',
    compliment: 'Compliment',
    others: 'Others',
    search: 'Searching Highlights, please wait...',
    title: "Winnie's Highlights",
  },
  _message: {
    title: "I'm working on your Highlights!",
    subtitle: "When I'm done, you'll receive an email notification. Please wait a moment!",
    _insufficient: {
      title: 'Oops, the minimum number of comments has not been reached yet.',
      subtitle: `Check back soon to see the results!`,
    },
  },
  word: `Keyword`,
};

export default {
  key: 'openQuestions',
  translations: {
    ptBr,
    es,
    en,
  },
};
