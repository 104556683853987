const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-yellow-400 mb-6 lg:mb-0 font-batusa">
      Ranking de Valores
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      A classificação dos valores mais importantes para a empresa destaca quais princípios são mais predominantes e permite uma compreensão clara das prioridades culturais da organização.
    </p>`,
  title: `Ranking de Valores`,
  value: 'Distribuição por Valores',
  question: 'Distribuição por Perguntas',
  _sectionTitle: {
    current: `Valor Atual`,
    central: `Valor Central`,
    desired: `Valor Desejado`,
    process: `Processos estruturados`,
    collaboration: `Colaboração e cooperação`,
    efficiency: `Eficácia e eficiência`,
    spirit: `Espírito de equipe`,
    focus: `Foco no cliente`,
    development: `Desenvolvimento humano`,
    expansion: `Expansão e crescimento`,
    excellence: `Excelência operacional`,
    environment: `Ambiente de família`,
    dynamism: `Dinamismo`,
    experiment: `Experimentação`,
    competition: `Competição e meritocracia`,
    innovation: `Foco em inovação`,
    rigor: `Rigor analítico`,
    appetite: `Apetite para desafio`,
    orientation: `Orientação para o resultado`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-yellow-400 mb-6 lg:mb-0 font-batusa">
      Ranking de Valores
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      La clasificación de los valores más importantes para la empresa destaca qué principios son más predominantes y permite una comprensión clara de las prioridades culturales de la organización.
    </p>`,
  title: `Ranking de Valores`,
  value: 'Distribución por Valores',
  question: 'Distribución por Preguntas',
  _sectionTitle: {
    current: `Valor Actual`,
    central: `Valor Central`,
    desired: `Valor Deseado`,
    process: `Procesos Estructurados`,
    collaboration: `Colaboración y Cooperación`,
    efficiency: `Eficacia y Eficiencia`,
    spirit: `Espíritu de Equipo`,
    focus: `Enfoque en el Cliente`,
    development: `Desarrollo Humano`,
    expansion: `Expansión y Crecimiento`,
    excellence: `Excelencia Operacional`,
    environment: `Ambiente Familiar`,
    dynamism: `Dinamismo`,
    experiment: `Experimentación`,
    competition: `Competencia y Meritocracia`,
    innovation: `Enfoque en Innovación`,
    rigor: `Rigor Analítico`,
    appetite: `Apetito para el Desafío`,
    orientation: `Orientación a Resultados`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-yellow-400 mb-6 lg:mb-0 font-batusa">
      Ranking of Values
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
       The ranking of the most important values for the company highlights which principles are most predominant and allows for a clear understanding of the organization's cultural priorities.
    </p>`,
  title: `Ranking of Values`,
  value: 'Distribution by Values',
  question: 'Distribution by Questions',
  _sectionTitle: {
    current: `Current Value`,
    central: `Central Value`,
    desired: `Desired Value`,
    process: `Structured Processes`,
    collaboration: `Collaboration and Cooperation`,
    efficiency: `Effectiveness and Efficiency`,
    spirit: `Team Spirit`,
    focus: `Customer Focus`,
    development: `Human Development`,
    expansion: `Expansion and Growth`,
    excellence: `Operational Excellence`,
    environment: `Family Environment`,
    dynamism: `Dynamism`,
    experiment: `Experimentation`,
    competition: `Competition and Meritocracy`,
    innovation: `Focus on Innovation`,
    rigor: `Analytical Rigor`,
    appetite: `Appetite for Challenge`,
    orientation: `Results Orientation`,
  },
};

export default {
  key: 'cultureValues',
  translations: {
    ptBr,
    es,
    en,
  },
};
