
import * as awsAmplifyRuntime$E4gHDIaK5x from '/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@nuxt/image/dist/runtime/providers/awsAmplify'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "awsAmplify",
  "domains": [
    "\"d2ovsjpt75gs86.cloudfront.net\""
  ],
  "alias": {
    "/winx": "https://d2ovsjpt75gs86.cloudfront.net/"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['awsAmplify']: { provider: awsAmplifyRuntime$E4gHDIaK5x, defaults: {} }
}
        