const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
    Dimensões
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      A opinião da família sobre o bem-estar dos colaboradores é importante para a empresa porque oferece uma
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">perspectiva externa</span> e
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">íntima</span>
      sobre a satisfação e saúde mental dos funcionários. Com os resultados a seguir, veja como as
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">famílias</span>
      entendem como a sua empresa valoriza o bem-estar integral de seus colaboradores.
    </p>`,
  title: 'Dimensões da Família',
  _mind: {
    title: `Mente`,
    message: `A dimensão "Mente" refere-se à capacidade de foco, gratificação quanto ao aprendizado que leva ao crescimento pessoal e profissional. Inclui a oportunidade de feedbacks que aceleram o desenvolvimento do indivíduo, bem como seu reconhecimento e valorização.`,
  },
  _body: {
    title: `Corpo`,
    message: `A dimensão "Corpo" foca na saúde do colaborador, no ambiente físico de trabalho e nas suas condições, na disciplina com cuidados médicos, na qualidade do sono, no bem-estar físico e na alimentação.`,
  },
  _soul: {
    title: `Espírito`,
    message: `A dimensão "Espírito" foca no senso de conexão com as pessoas e com a cultura organizacional, na admiração pela marca e proposta de valor da empresa, no orgulho das realizações e no senso de propósito.`,
  },
  _feeling: {
    title: `Emoção`,
    message: `A dimensão "Emoção" foca no aspecto relacional, na capacidade de lidar com pressão, no otimismo e positividade com o trabalho, na felicidade e bem-estar emocional, assim como no equilíbrio de tempo entre questões pessoais e profissionais.`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
    Dimensiones
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      La opinión de la familia sobre el bienestar de los empleados es importante para la empresa porque ofrece una
      sobre la satisfacción y la salud mental de los empleados. Con los resultados a continuación,
      vea cómo las familias entienden cómo su empresa valora el bienestar integral de sus empleados.
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">perspectiva externa</span> e
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">íntima</span>
      sobre la satisfacción y la salud mental de los empleados. Con los resultados a continuación, vea cómo las
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">familias</span>
      entienden cómo su empresa valora el bienestar integral de sus empleados.
    </p>`,
  title: 'Dimensiones Familiares',
  _mind: {
    title: `Mente`,
    message: `La dimensión "Mente" se refiere a la capacidad de concentración, gratificación en el aprendizaje que conduce al crecimiento personal y profesional. Incluye la oportunidad de retroalimentación que acelere el desarrollo del individuo, así como su reconocimiento y aprecio.`,
  },
  _body: {
    title: `Cuerpo`,
    message: `La dimensión “Cuerpo” se centra en la salud del trabajador, el entorno físico de trabajo y sus condiciones, la disciplina con atención médica, la calidad del sueño, el bienestar físico y la nutrición.`,
  },
  _soul: {
    title: `Espíritu`,
    message: `La dimensión "Espíritu" se centra en el sentido de conexión con las personas y la cultura organizacional, la admiración por la marca y la propuesta de valor de la empresa, el orgullo por los logros y el sentido de propósito.`,
  },
  _feeling: {
    title: `Emoción`,
    message: `La dimensión “Emoción” se centra en el aspecto relacional, la capacidad de afrontar la presión, el optimismo y la positividad con el trabajo, la felicidad y el bienestar emocional, así como el equilibrio de tiempo entre las cuestiones personales y profesionales.`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
    Dimensions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Family feedback on employee well-being is important to your company because it provides an
      on employee satisfaction and mental health. With the results below,
      see how families understand how your company values the overall well-being of its employees.
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">outside</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">intimate perspective</span>
      on employee satisfaction and mental health. With the results below, see how
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">families</span>
      understand how your company values the overall well-being of its employees.
    </p>`,
  title: 'Family Dimensions',
  _mind: {
    title: `Mind`,
    message: `The "Mind" dimension refers to the ability to focus, gratification regarding learning that leads to personal and professional growth. It includes the opportunity for feedback that accelerates the individual's development, as well as their recognition and appreciation.`,
  },
  _body: {
    title: `Body`,
    message: `The "Body" dimension focuses on the employee's health, the physical work environment and its conditions, discipline with medical care, sleep quality, physical well-being and nutrition.`,
  },
  _soul: {
    title: `Spirit`,
    message: `The "Spirit" dimension focuses on the sense of connection with people and the organizational culture, admiration for the company's brand and value proposition, pride in achievements and a sense of purpose.`,
  },
  _feeling: {
    title: `Emotion`,
    message: `The "Emotion" dimension focuses on the relational aspect, the ability to deal with pressure, optimism and positivity at work, happiness and emotional well-being, as well as the balance of time between personal and professional issues.`,
  },
};

export default {
  key: 'familyDimension',
  translations: {
    ptBr,
    es,
    en,
  },
};
