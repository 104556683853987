const ptBr = {
  generalData: `Dados Gerais`,
  favorability: `Favorabilidade`,
  terribleFavorability: `Favorabilidade Péssima`,
  badFavorability: `Favorabilidade Ruim`,
  regularFavorability: `Favorabilidade Regular`,
  goodFavorability: `Favorabilidade Boa`,
  excellentFavorability: `Favorabilidade Excelente`,
  effectiveness: `Efetividade`,
  badEffectiveness: `Efetividade`,
  regularEffectiveness: `Efetividade`,
  excellentEffectiveness: `Efetividade`,
  hightTurnover: `Alto potencial de turnover`,
  undecideds: `Indecisos`,
  satisfied: `Satisfeitos`,
  fullyCommitted: `Totalmente comprometidos`,
  title: 'Resumo',
  highestQuestions: `Perguntas com Maior Favorabilidade`,
  lowestQuestions: `Perguntas com Menor Favorabilidade`,
  participation: `Participação`,
  collaborators: `Colaboradores`,
  respondents: `Respondentes`,
  informativeDescription: {
    _engagementDiagnosis: `
      O Diagnóstico de Clima e Engajamento oferece uma análise aprofundada da efetividade da liderança, mensura o clima organizacional e o engajamento dos colaboradores. Ele identifica influenciadores internos, avalia riscos de turnover e destaca áreas de melhoria na experiência dos colaboradores.
    `,
    _cultureDiagnosis: `
      O Diagnóstico de Cultura Organizacional analisa os elementos da Cultura (Propósito e Valores) e da Estratégia (Missão e Visão), mede a efetividade da cultura, ranqueia e compara valores atuais e desejados. Isso permite entender como valores e comportamentos influenciam o ambiente de trabalho e os resultados da empresa, identificar áreas de melhoria e alinhar a cultura organizacional à estratégia do negócio.
    `,
    _familyDiagnosis: `
            O Diagnóstico da Família envolve a coleta e análise das opiniões dos familiares sobre a experiência de trabalho dos seus entes queridos na empresa. Este feedback é crucial para identificar áreas de melhoria que impactam diretamente o bem-estar e a motivação dos colaboradores, resultando em um ambiente de trabalho mais harmonioso e produtivo.
        `,
    _cultureBoosterh: `
      O BoosteRH de Cultura é uma ferramenta de escuta contínua que utiliza inteligência artificial para fornecer recomendações e feedbacks valiosos sobre a cultura organizacional. Este diagnóstico mede a efetividade da cultura organizacional (propósito e valores) e da estratégia (missão e valores) de acordo com o nível de força e saudabilidade que elas têm, permitindo a tomada de ações proativas que melhoram o ambiente de trabalho e aumentam a satisfação dos colaboradores.
    `,
    _engagementBoosterh: `
      O BoosteRH de Clima e Engajamento é uma ferramenta para dimensionar e qualificar a efetividade da liderança, analisar profundamente o clima organizacional e mensurar o nível de engajamento dos colaboradores, fornecendo recomendações acionáveis. Ao sondar o cenário atual e direcionar ações futuras, o sistema permite que RH e líderes tomem decisões baseadas em dados e adotem medidas proativas, fortalecendo a motivação e a produtividade no ambiente de trabalho.
    `,
    _ocaiBoosterh: `
      O BoosteRH de OCAI, baseado no Organizational Culture Assessment Instrument (OCAI) da Universidade de Michigan, compara a cultural atual com a desejada, o que  possibilita identificar a visão de presente dos colaboradores sobre a cultura vivenciada e sua visão aspirafional de cultura.
    `,
    _experienceBoosterh: `
      O BoosteRH de Experiência do Colaborador é uma ferramenta que analisa as etapas da jornada do indíviduo junto à organização, proporcionando entender onde estão os principais obstáculos da jornada para facilitar o direcionamento da estratégia de gestão de pessoas. Com isso, é possível aumentar o engajamento, promover a alta performance dos times e reter talentos.
    `,
    _leadershipBoosterh: `
      O BoosteRH de Liderança usa inteligência artificial para qualificar gestores com recomendações e feedbacks valiosos. Ele identifica áreas de melhoria, promove ações proativas e eleva a qualidade da liderança, impactando positivamente o ambiente de trabalho.
    `,
    _influencerBoosterh: `
            O Boosterh de Influenciadores avalia como determinados colaboradores se destacam como Agentes de Mudanças, Mentores ou Experts dentro da empresa.
            Utilizando inteligência artificial generativa, identifica áreas de melhoria e sugere intervenções, proporcionando recomendações de ações que visam
            aprimorar o desempenho e o impacto desses colaboradores-chave.
        `,
    _attritionBoosterhTitle: `
      BoosteRH de Turnover + eNPS
    `,
    _attritionBoosterh: `
      O BoosteRH de Turnover + eNPS analisa e prevê o risco de perda de talentos dentro da organização, além de avaliar a lealdade e o engajamento dos colaboradores na medida em que recomendam a empresa com um ótimo lugar de trabalho bem como seus produtos e serviços a familiares e amigos. Ao identificar questões críticas e sugerir ações específicas para o RH e os líderes, o BoosteRH de Turnover + eNPS contribui para a criação de um ambiente satisfatório, impactando diretamente a retenção de talentos e o bem-estar no trabalho.
    `,
  },
};

const en = {
  generalData: `General Data`,
  favorability: `Favorability`,
  terribleFavorability: `Terrible Favorability`,
  badFavorability: 'Bad Favorability',
  regularFavorability: 'Regular Favorability',
  goodFavorability: `Good Favorability`,
  excellentFavorability: 'Excellent Favorability',
  effectiveness: `Effectiveness`,
  badEffectiveness: `Effectiveness`,
  regularEffectiveness: `Effectiveness`,
  excellentEffectiveness: `Effectiveness`,
  hightTurnover: `High turnover potential`,
  undecideds: `Undecided`,
  satisfied: `Satisfied`,
  fullyCommitted: `Fully committed`,
  title: 'Summary',
  highestQuestions: `Questions with Highest Favorability`,
  lowestQuestions: `Questions with Lowest Favorability`,
  participation: `Participation`,
  collaborators: `Collaborators`,
  respondents: `Respondents`,
  informativeDescription: {
    _engagementDiagnosis: `
      The Climate and Engagement Diagnosis provides an in-depth analysis of leadership effectiveness, and measures organizational climate and employee engagement. It identifies internal influencers, assesses turnover risks, and highlights areas to improve the employee experience.
    `,
    _cultureDiagnosis: `
      The Organizational Culture Diagnosis analyzes the elements of Culture (Purpose and Values) and Strategy (Mission and Vision), measures the effectiveness of the culture, ranks and compares current and desired values. This allows understanding how values and behaviors influence the work environment and company results, identify areas for improvement, and align organizational culture with business strategy.
    `,
    _familyDiagnosis: `
            Family Diagnosis involves collecting and analyzing family members' opinions about their loved ones' work experience at the company. This feedback is crucial for identifying improvement areas that directly impact employees' well-being and motivation, resulting in a more harmonious and productive work environment.
        `,
    _cultureBoosterh: `
      The Culture BoosteRH is a continuous listening tool that uses artificial intelligence to provide valuable recommendations and feedback on organizational culture. This assessment measures the effectiveness of organizational culture (purpose and values) and strategy (mission and values) according to their level of strength and healthfulness, promoting proactive actions that improve the work environment and increase employee satisfaction.
    `,
    _engagementBoosterh: `
      The Climate and Engagement BoosteRH is a tool designed to assess and qualify leadership effectiveness, deeply analyze the organizational climate, and measure employees' level of engagement, providing actionable recommendations. By probing the current scenario and guiding future actions, the system enables HR and leaders to make data-driven decisions and adopt proactive measures, enhancing motivation and productivity in the workplace.
    `,
    _ocaiBoosterh: `
      The OCAI BoosteRH, based on the Organizational Culture Assessment Instrument (OCAI) from the University of Michigan, compares the current culture with the desired one, which enables the identification of employees' current view of the culture they experience and their aspirational vision of culture.
    `,
    _experienceBoosterh: `
      The Employee Experience BoosteRH is a tool that analyzes the stages of an individual's journey with the organization, providing an understanding of where the main obstacles in the journey are, to facilitate the direction of people management strategy. This enables increased engagement, promotes high team performance, and retains talent.
    `,
    _leadershipBoosterh: `
      The Leadership BoosteRH uses artificial intelligence to qualify managers with valuable recommendations and feedback. It identifies areas for improvement, promotes proactive actions, and enhances leadership quality, positively impacting the work environment.
    `,
    _influencerBoosterh: `
            BoosteRH of Influencers evaluates how certain employees stand out as Change Agents, Mentors, or Experts within the company.
            Using generative artificial intelligence, it identifies areas for improvement and suggests interventions, providing recommendations on
            actions aimed at enhancing the performance and impact of these key employees.
        `,
    _attritionBoosterhTitle: `
      BoosteRH of Turnover + eNPS
    `,
    _attritionBoosterh: `
      The Turnover + eNPS BoosteRH analyzes and predicts the risk of talent loss within the organization, in addition to assessing the loyalty and engagement of employees as they recommend the company as a great place to work as well as its products and services to family and friends. By identifying critical issues and suggesting specific actions for HR and leaders, the Turnover + eNPS BoosteRH contributes to creating a satisfactory environment, directly impacting talent retention and well-being at work.
    `,
  },
};

const es = {
  generalData: `Datos Generales`,
  favorability: `Favorabilidad`,
  terribleFavorability: `Horrible Favorabilidad`,
  badFavorability: 'Baja Favorabilidad',
  regularFavorability: 'Favorabilidad Regular',
  goodFavorability: `Buena Favorabilidad`,
  excellentFavorability: 'Excelente Favorabilidad',
  effectiveness: `Eficacia`,
  badEffectiveness: `Eficacia`,
  regularEffectiveness: `Eficacia`,
  excellentEffectiveness: `Eficacia`,
  hightTurnover: `Alto potencial de rotación`,
  undecideds: `Indecisos`,
  satisfied: `Satisfechos`,
  fullyCommitted: `Totalmente comprometido`,
  title: 'Resumen',
  highestQuestions: `Preguntas con mayor favorabilidad`,
  lowestQuestions: `Preguntas con menor favorabilidad`,
  participation: `Participación`,
  collaborators: `Colaboradores`,
  respondents: `Respondentes`,
  informativeDescription: {
    _engagementDiagnosis: `
      El Diagnóstico de Clima y Compromiso ofrece un análisis profundo de la efectividad del liderazgo, mide el clima organizacional y el compromiso de los empleados. Identifica influenciadores internos, evalúa riesgos de rotación y resalta áreas para mejorar la experiencia del empleado.
    `,
    _cultureDiagnosis: `
      El Diagnóstico de Cultura Organizacional analiza los elementos de la Cultura (Propósito y Valores) y de la Estrategia (Misión y Visión), mide la efectividad de la cultura, clasifica y compara valores actuales y deseados. Esto permite entender cómo los valores y comportamientos influyen en el ambiente de trabajo y en los resultados de la empresa, identificar áreas de mejora y alinear la cultura organizacional con la estrategia del negocio.
    `,
    _familyDiagnosis: `
            El Diagnóstico de la Familia implica la recolección y análisis de las opiniones de los familiares sobre la experiencia laboral de sus seres queridos en la empresa. Este feedback es crucial para identificar áreas de mejora que impactan directamente en el bienestar y la motivación de los empleados, resultando en un ambiente de trabajo más armonioso y productivo.
        `,
    _cultureBoosterh: `
      El BoosteRH de Cultura es una herramienta de escucha continua que utiliza inteligencia artificial para proporcionar recomendaciones y retroalimentaciones valiosas sobre la cultura organizacional. Este diagnóstico mide la efectividad de la cultura organizacional (propósito y valores) y de la estrategia (misión y valores) según el nivel de fuerza y salud que tienen, permitiendo la toma de acciones proactivas que mejoran el ambiente de trabajo y aumentan la satisfacción de los colaboradores.
    `,
    _engagementBoosterh: `
      El BoosteRH de Clima y Compromiso es una herramienta para dimensionar y calificar la efectividad del liderazgo, analizar en profundidad el clima organizacional y medir el nivel de compromiso de los empleados, proporcionando recomendaciones accionables. Al sondear el escenario actual y dirigir acciones futuras, el sistema permite que RRHH y los líderes tomen decisiones basadas en datos y adopten medidas proactivas, fortaleciendo la motivación y la productividad en el ambiente de trabajo.
    `,
    _ocaiBoosterh: `
      El BoosteRH de OCAI, basado en el Instrumento de Evaluación de Cultura Organizacional (OCAI) de la Universidad de Michigan, compara la cultura actual con la deseada, lo que permite identificar la visión presente de los colaboradores sobre la cultura vivida y su visión aspiracional de cultura.
    `,
    _experienceBoosterh: `
      El BoosteRH de Experiencia del Colaborador es una herramienta que analiza las etapas del viaje del individuo junto a la organización, proporcionando un entendimiento de dónde están los principales obstáculos del viaje para facilitar la dirección de la estrategia de gestión de personas. Con esto, es posible aumentar el compromiso, promover el alto rendimiento de los equipos y retener talentos.
    `,
    _leadershipBoosterh: `
      El BoosteRH de Liderazgo utiliza inteligencia artificial para calificar a los gestores con recomendaciones y retroalimentación valiosa. Identifica áreas de mejora, promueve acciones proactivas y mejora la calidad del liderazgo, impactando positivamente el ambiente laboral.
    `,
    _influencerBoosterh: `
            El BoosteRH de Influenciadores evalúa cómo ciertos empleados se destacan como Agentes de Cambio, Mentores o Expertos dentro
            de la empresa. Utilizando inteligencia artificial generativa, identifica áreas de mejora y sugiere intervenciones,
            proporcionando recomendaciones de acciones que buscan mejorar el rendimiento y el impacto de estos empleados clave.
        `,
    _attritionBoosterhTitle: `
      BoosteRH de Rotación de Personal + eNPS
    `,
    _attritionBoosterh: `
      El BoosteRH de Rotación + eNPS analiza y predice el riesgo de pérdida de talentos dentro de la organización, además de evaluar la lealtad y el compromiso de los colaboradores en la medida en que recomiendan la empresa como un gran lugar de trabajo así como sus productos y servicios a familiares y amigos. Al identificar cuestiones críticas y sugerir acciones específicas para RRHH y líderes, el BoosteRH de Rotación + eNPS contribuye a la creación de un entorno satisfactorio, impactando directamente la retención de talentos y el bienestar en el trabajo.
    `,
  },
};

export default {
  key: 'resume',
  translations: {
    ptBr,
    es,
    en,
  },
};
