const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4 underline decoration-4 decoration-orange-400 underline-offset-8 font-batusa">
      Clusters de Attrition
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left mb-2">
      Os clusters de attrition representam a vontade dos colaboradores em relação ao presente e futuro da empresa.
      Os clusters evidenciam o grau de comprometimento dos colaboradores com o presente e o futuro da empresa, sinalizando risco de turnover.
      Os colaboradores são divididos em 4 grupos:
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Totalmente comprometidos</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-teal-200">Satisfeitos</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Indecisos</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Alto potencial de turnover</span>.
    </p>
    `,
  title: `Clusters de Attrition`,
  engaged: 'Totalmente comprometidos',
  satisfied: 'Satisfeitos',
  undecided: 'Indecisos',
  turnover: 'Alto potencial de turnover',
  _tooltip: {
    engaged: 'Estão comprometidos com o presente e têm vontade de futuro com a organização.',
    satisfied: 'Estão comprometidos com o momento presente.',
    undecided: 'Sentem que estão perdendo seu comprometimento.',
    turnover: 'Perderam seu comprometimento e esperam a melhor oportunidade para sair.',
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-3 underline decoration-4 underline-offset-8 decoration-blue-200 font-batusa">
      Clusters de Attrition
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left mb-2">
      Los clusters de attrition representan la disposición de los empleados con respecto al presente y futuro de la empresa.
      Estos clusters evidencian el grado de compromiso de los empleados con el presente y futuro de la empresa, señalando el riesgo de rotación.
      Los empleados se dividen en cuatro grupos:
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Totalmente Comprometidos</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-teal-200">Satisfechos</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Indecisos</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Alto Potencial de Rotación</span>.
    </p>
    `,
  title: `Clusters de Evasión`,
  engaged: 'Totalmente Comprometidos',
  satisfied: 'Satisfechos',
  undecided: 'Indecisos',
  turnover: 'Alto Potencial de Rotación',
  _tooltip: {
    engaged: 'Están comprometidos con el presente y tienen el deseo de un futuro con la organización.',
    satisfied: 'Están comprometidos con el momento presente.',
    undecided: 'Sienten que están perdiendo su compromiso.',
    turnover: 'Han perdido su compromiso y están esperando la mejor oportunidad para irse.',
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-3 underline decoration-4 underline-offset-8 decoration-blue-200 font-batusa">
      Attrition Clusters
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left mb-2">
      Attrition clusters represent the employees' willingness concerning the present and future of the company.
      These clusters highlight the degree of commitment employees have with the company's present and future, indicating the risk of turnover.
      Employees are divided into four groups:
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Fully Committed</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-teal-200">Satisfied</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Undecided</span>;
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">High Turnover Potential</span>.
    </p>
    `,
  title: `Attrition Clusters`,
  engaged: 'Fully committed',
  satisfied: 'Satisfied',
  undecided: 'Undecided',
  turnover: 'High turnover potential',
  _tooltip: {
    engaged: 'They are committed to the present and have a desire for a future with the organization.',
    satisfied: 'They are committed to the present moment.',
    undecided: 'They feel they are losing their commitment.',
    turnover: 'They have lost their commitment and are waiting for the best opportunity to leave.',
  },
};

export default {
  key: 'attritionClusters',
  translations: {
    ptBr,
    es,
    en,
  },
};
