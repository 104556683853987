import { default as adhesionuD9fWYhSJGMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/adhesion.vue?macro=true";
import { default as attrition_45clusters6lnPjY2lUJMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/attrition-clusters.vue?macro=true";
import { default as indexNpwkgQl1V7Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/index.vue?macro=true";
import { default as _91id_93srlayTlUFlMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/[id].vue?macro=true";
import { default as _91id_93VmbskCMGqoMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/[id].vue?macro=true";
import { default as _91id_93FZB30kEppoMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/task/[id].vue?macro=true";
import { default as _91id_93wkPFYBmpyMMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/[id].vue?macro=true";
import { default as index2IzReQG7PUMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/index.vue?macro=true";
import { default as advicesTO9GKu4TMcMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/advices.vue?macro=true";
import { default as employee_45recommendationlsmEV2wUm4Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/employee-recommendation.vue?macro=true";
import { default as hr_45recommendationwS1WLyIa2kMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/hr-recommendation.vue?macro=true";
import { default as index4iQzrDzniiMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/index.vue?macro=true";
import { default as leader_45recommendationEjHSWBwBmaMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/leader-recommendation.vue?macro=true";
import { default as task_45implementationgIGe3aUAsjMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/task-implementation.vue?macro=true";
import { default as highlights8qFfhBxIxGMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/highlights.vue?macro=true";
import { default as index4Csghi5nXHMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/index.vue?macro=true";
import { default as commentsQext7zQsTPMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/comments.vue?macro=true";
import { default as missionYWeQzHXSj3Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/mission.vue?macro=true";
import { default as purpose2oH8wBm9Y6Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/purpose.vue?macro=true";
import { default as valueQx92UHGA2WMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/value.vue?macro=true";
import { default as visionHVmeWSioYZMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/vision.vue?macro=true";
import { default as culture_45elementszFzsDExBj5Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements.vue?macro=true";
import { default as aspirationalUb8nv2RrTGMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/aspirational.vue?macro=true";
import { default as current8mHckiMLwQMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/current.vue?macro=true";
import { default as culture_45typesGH0m0ARqXIMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types.vue?macro=true";
import { default as questionSFkDW7uYvjMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/question.vue?macro=true";
import { default as valuebA0aoKchxIMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/value.vue?macro=true";
import { default as culture_45values4Y4cgZKmxmMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values.vue?macro=true";
import { default as customized_45questionsLLJPnfICO8Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/customized-questions.vue?macro=true";
import { default as demographics1E50iHbs43Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/demographics.vue?macro=true";
import { default as dimensionFUFXLt55mXMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/dimension.vue?macro=true";
import { default as dissonanceeEHVVS8HBvMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/dissonance.vue?macro=true";
import { default as healthiness8iLBAHCIUtMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/healthiness.vue?macro=true";
import { default as strengthRKKqncS9dSMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/strength.vue?macro=true";
import { default as effectivenessOalvugDeFGMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness.vue?macro=true";
import { default as engagement_45clustersz10LMbB1DdMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-clusters.vue?macro=true";
import { default as managerlva8ofmUqkMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/manager.vue?macro=true";
import { default as organizationSNHkIarlzDMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/organization.vue?macro=true";
import { default as team7YDndy5Bk7Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/team.vue?macro=true";
import { default as workLuYkH38pFsMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/work.vue?macro=true";
import { default as engagement_45dimensionfKwp4KhnzSMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension.vue?macro=true";
import { default as companybFJrTkSi6mMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/company.vue?macro=true";
import { default as defaultNkSmi4kH1nMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/default.vue?macro=true";
import { default as indexqwmP6pF1fyMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/index.vue?macro=true";
import { default as leadershipfXs98c80UkMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/leadership.vue?macro=true";
import { default as productjp8daF4fzDMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/product.vue?macro=true";
import { default as teamL5qfEMH6eJMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/team.vue?macro=true";
import { default as enpsBNJxo6DPgUMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps.vue?macro=true";
import { default as experience_45dimension3JmV9nhg46Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/experience-dimension.vue?macro=true";
import { default as bodypev7GVnucEMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/body.vue?macro=true";
import { default as feeling635adnzEtAMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/feeling.vue?macro=true";
import { default as mindFWJcL6xGGkMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/mind.vue?macro=true";
import { default as soulClXex3Ng5aMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/soul.vue?macro=true";
import { default as family_45dimensionKSHzknM3Q5Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension.vue?macro=true";
import { default as flowg033yNOUdoMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/flow.vue?macro=true";
import { default as indexkQKkrdw4VsMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/index.vue?macro=true";
import { default as agentshTwigU7m3NMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/agents.vue?macro=true";
import { default as expertsaAvhrttZroMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/experts.vue?macro=true";
import { default as mentorsS3jHaWofoUMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/mentors.vue?macro=true";
import { default as influencerswSZjeYd0SWMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers.vue?macro=true";
import { default as autonomy8uQIanL8LEMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/autonomy.vue?macro=true";
import { default as connectionnyrVaFvNyCMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/connection.vue?macro=true";
import { default as growthV5JTlcQ5f5Meta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/growth.vue?macro=true";
import { default as impactxoKgFs4QBJMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/impact.vue?macro=true";
import { default as meaningplUHANeHjFMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/meaning.vue?macro=true";
import { default as magicxnKCBdRZrLMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic.vue?macro=true";
import { default as highlightsPFZBrBZd1AMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/highlights.vue?macro=true";
import { default as indexLLei7YTLahMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/index.vue?macro=true";
import { default as open_45questionsDVuVO8xRNtMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions.vue?macro=true";
import { default as ranking_45by_45filterZ3diDouRRHMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-filter.vue?macro=true";
import { default as ranking_45by_45questionEbD0nKcyyCMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-question.vue?macro=true";
import { default as resumei2jcsv9i7SMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/resume.vue?macro=true";
import { default as _91_91campaign_93_93mC93v7OcjcMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]].vue?macro=true";
import { default as indexcJ4pV2zNUiMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/index.vue?macro=true";
import { default as inside_45consultant_45employeerp0KUF7ytcMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/inside-consultant-employee.vue?macro=true";
import { default as logincqAuUBOzmGMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/login.vue?macro=true";
import { default as my_45surveysgRtgQ19HYoMeta } from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/my-surveys.vue?macro=true";
export default [
  {
    name: _91_91campaign_93_93mC93v7OcjcMeta?.name,
    path: "/:campaign?",
    meta: _91_91campaign_93_93mC93v7OcjcMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]].vue"),
    children: [
  {
    name: "campaign-adhesion",
    path: "adhesion",
    meta: adhesionuD9fWYhSJGMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/adhesion.vue")
  },
  {
    name: "campaign-attrition-clusters",
    path: "attrition-clusters",
    meta: attrition_45clusters6lnPjY2lUJMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/attrition-clusters.vue")
  },
  {
    name: "campaign-boosterh-question-advice",
    path: "boosterh/:question()/:advice()",
    meta: indexNpwkgQl1V7Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/index.vue")
  },
  {
    name: "campaign-boosterh-question-advice-objective-id",
    path: "boosterh/:question()/:advice()/objective/:id()",
    meta: _91id_93srlayTlUFlMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-objective-assignment-id",
    path: "boosterh/:question()/:advice()/objective/assignment/:id()",
    meta: _91id_93VmbskCMGqoMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-objective-assignment-task-id",
    path: "boosterh/:question()/:advice()/objective/assignment/task/:id()",
    meta: _91id_93FZB30kEppoMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/task/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-plans-plan-id",
    path: "boosterh/:question()/:advice()/plans-:plan()/:id()",
    meta: _91id_93wkPFYBmpyMMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-plans-plan",
    path: "boosterh/:question()/:advice()/plans-:plan()",
    meta: index2IzReQG7PUMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/index.vue")
  },
  {
    name: "campaign-boosterh-question-advices",
    path: "boosterh/:question()/advices",
    meta: advicesTO9GKu4TMcMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/advices.vue")
  },
  {
    name: "campaign-boosterh-employee-recommendation",
    path: "boosterh/employee-recommendation",
    meta: employee_45recommendationlsmEV2wUm4Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/employee-recommendation.vue")
  },
  {
    name: "campaign-boosterh-hr-recommendation",
    path: "boosterh/hr-recommendation",
    meta: hr_45recommendationwS1WLyIa2kMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/hr-recommendation.vue")
  },
  {
    name: "campaign-boosterh",
    path: "boosterh",
    meta: index4iQzrDzniiMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/index.vue")
  },
  {
    name: "campaign-boosterh-leader-recommendation",
    path: "boosterh/leader-recommendation",
    meta: leader_45recommendationEjHSWBwBmaMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/leader-recommendation.vue")
  },
  {
    name: "campaign-boosterh-task-implementation",
    path: "boosterh/task-implementation",
    meta: task_45implementationgIGe3aUAsjMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/task-implementation.vue")
  },
  {
    name: commentsQext7zQsTPMeta?.name,
    path: "comments",
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/comments.vue"),
    children: [
  {
    name: "campaign-comments-highlights",
    path: "highlights",
    meta: highlights8qFfhBxIxGMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/highlights.vue")
  },
  {
    name: "campaign-comments",
    path: "",
    meta: index4Csghi5nXHMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/index.vue")
  }
]
  },
  {
    name: "campaign-culture-elements",
    path: "culture-elements",
    meta: culture_45elementszFzsDExBj5Meta || {},
    redirect: culture_45elementszFzsDExBj5Meta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements.vue"),
    children: [
  {
    name: "campaign-culture-elements-mission",
    path: "mission",
    meta: missionYWeQzHXSj3Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/mission.vue")
  },
  {
    name: "campaign-culture-elements-purpose",
    path: "purpose",
    meta: purpose2oH8wBm9Y6Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/purpose.vue")
  },
  {
    name: "campaign-culture-elements-value",
    path: "value",
    meta: valueQx92UHGA2WMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/value.vue")
  },
  {
    name: "campaign-culture-elements-vision",
    path: "vision",
    meta: visionHVmeWSioYZMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/vision.vue")
  }
]
  },
  {
    name: "campaign-culture-types",
    path: "culture-types",
    meta: culture_45typesGH0m0ARqXIMeta || {},
    redirect: culture_45typesGH0m0ARqXIMeta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types.vue"),
    children: [
  {
    name: "campaign-culture-types-aspirational",
    path: "aspirational",
    meta: aspirationalUb8nv2RrTGMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/aspirational.vue")
  },
  {
    name: "campaign-culture-types-current",
    path: "current",
    meta: current8mHckiMLwQMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/current.vue")
  }
]
  },
  {
    name: "campaign-culture-values",
    path: "culture-values",
    meta: culture_45values4Y4cgZKmxmMeta || {},
    redirect: culture_45values4Y4cgZKmxmMeta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values.vue"),
    children: [
  {
    name: "campaign-culture-values-question",
    path: "question",
    meta: questionSFkDW7uYvjMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/question.vue")
  },
  {
    name: "campaign-culture-values-value",
    path: "value",
    meta: valuebA0aoKchxIMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/value.vue")
  }
]
  },
  {
    name: "campaign-customized-questions",
    path: "customized-questions",
    meta: customized_45questionsLLJPnfICO8Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/customized-questions.vue")
  },
  {
    name: "campaign-demographics",
    path: "demographics",
    meta: demographics1E50iHbs43Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/demographics.vue")
  },
  {
    name: "campaign-dimension",
    path: "dimension",
    meta: dimensionFUFXLt55mXMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/dimension.vue")
  },
  {
    name: "campaign-dissonance",
    path: "dissonance",
    meta: dissonanceeEHVVS8HBvMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/dissonance.vue")
  },
  {
    name: "campaign-effectiveness",
    path: "effectiveness",
    meta: effectivenessOalvugDeFGMeta || {},
    redirect: effectivenessOalvugDeFGMeta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness.vue"),
    children: [
  {
    name: "campaign-effectiveness-healthiness",
    path: "healthiness",
    meta: healthiness8iLBAHCIUtMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/healthiness.vue")
  },
  {
    name: "campaign-effectiveness-strength",
    path: "strength",
    meta: strengthRKKqncS9dSMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/strength.vue")
  }
]
  },
  {
    name: "campaign-engagement-clusters",
    path: "engagement-clusters",
    meta: engagement_45clustersz10LMbB1DdMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-clusters.vue")
  },
  {
    name: "campaign-engagement-dimension",
    path: "engagement-dimension",
    meta: engagement_45dimensionfKwp4KhnzSMeta || {},
    redirect: engagement_45dimensionfKwp4KhnzSMeta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension.vue"),
    children: [
  {
    name: "campaign-engagement-dimension-manager",
    path: "manager",
    meta: managerlva8ofmUqkMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/manager.vue")
  },
  {
    name: "campaign-engagement-dimension-organization",
    path: "organization",
    meta: organizationSNHkIarlzDMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/organization.vue")
  },
  {
    name: "campaign-engagement-dimension-team",
    path: "team",
    meta: team7YDndy5Bk7Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/team.vue")
  },
  {
    name: "campaign-engagement-dimension-work",
    path: "work",
    meta: workLuYkH38pFsMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/work.vue")
  }
]
  },
  {
    name: enpsBNJxo6DPgUMeta?.name,
    path: "enps",
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps.vue"),
    children: [
  {
    name: "campaign-enps-company",
    path: "company",
    meta: companybFJrTkSi6mMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/company.vue")
  },
  {
    name: "campaign-enps-default",
    path: "default",
    meta: defaultNkSmi4kH1nMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/default.vue")
  },
  {
    name: "campaign-enps",
    path: "",
    meta: indexqwmP6pF1fyMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/index.vue")
  },
  {
    name: "campaign-enps-leadership",
    path: "leadership",
    meta: leadershipfXs98c80UkMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/leadership.vue")
  },
  {
    name: "campaign-enps-product",
    path: "product",
    meta: productjp8daF4fzDMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/product.vue")
  },
  {
    name: "campaign-enps-team",
    path: "team",
    meta: teamL5qfEMH6eJMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/team.vue")
  }
]
  },
  {
    name: "campaign-experience-dimension",
    path: "experience-dimension",
    meta: experience_45dimension3JmV9nhg46Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/experience-dimension.vue")
  },
  {
    name: "campaign-family-dimension",
    path: "family-dimension",
    meta: family_45dimensionKSHzknM3Q5Meta || {},
    redirect: family_45dimensionKSHzknM3Q5Meta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension.vue"),
    children: [
  {
    name: "campaign-family-dimension-body",
    path: "body",
    meta: bodypev7GVnucEMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/body.vue")
  },
  {
    name: "campaign-family-dimension-feeling",
    path: "feeling",
    meta: feeling635adnzEtAMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/feeling.vue")
  },
  {
    name: "campaign-family-dimension-mind",
    path: "mind",
    meta: mindFWJcL6xGGkMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/mind.vue")
  },
  {
    name: "campaign-family-dimension-soul",
    path: "soul",
    meta: soulClXex3Ng5aMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/soul.vue")
  }
]
  },
  {
    name: "campaign-flow",
    path: "flow",
    meta: flowg033yNOUdoMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/flow.vue")
  },
  {
    name: "campaign",
    path: "",
    meta: indexkQKkrdw4VsMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/index.vue")
  },
  {
    name: "campaign-influencers",
    path: "influencers",
    meta: influencerswSZjeYd0SWMeta || {},
    redirect: influencerswSZjeYd0SWMeta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers.vue"),
    children: [
  {
    name: "campaign-influencers-agents",
    path: "agents",
    meta: agentshTwigU7m3NMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/agents.vue")
  },
  {
    name: "campaign-influencers-experts",
    path: "experts",
    meta: expertsaAvhrttZroMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/experts.vue")
  },
  {
    name: "campaign-influencers-mentors",
    path: "mentors",
    meta: mentorsS3jHaWofoUMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/mentors.vue")
  }
]
  },
  {
    name: "campaign-magic",
    path: "magic",
    meta: magicxnKCBdRZrLMeta || {},
    redirect: magicxnKCBdRZrLMeta?.redirect,
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic.vue"),
    children: [
  {
    name: "campaign-magic-autonomy",
    path: "autonomy",
    meta: autonomy8uQIanL8LEMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/autonomy.vue")
  },
  {
    name: "campaign-magic-connection",
    path: "connection",
    meta: connectionnyrVaFvNyCMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/connection.vue")
  },
  {
    name: "campaign-magic-growth",
    path: "growth",
    meta: growthV5JTlcQ5f5Meta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/growth.vue")
  },
  {
    name: "campaign-magic-impact",
    path: "impact",
    meta: impactxoKgFs4QBJMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/impact.vue")
  },
  {
    name: "campaign-magic-meaning",
    path: "meaning",
    meta: meaningplUHANeHjFMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/meaning.vue")
  }
]
  },
  {
    name: open_45questionsDVuVO8xRNtMeta?.name,
    path: "open-questions",
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions.vue"),
    children: [
  {
    name: "campaign-open-questions-highlights",
    path: "highlights",
    meta: highlightsPFZBrBZd1AMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/highlights.vue")
  },
  {
    name: "campaign-open-questions",
    path: "",
    meta: indexLLei7YTLahMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/index.vue")
  }
]
  },
  {
    name: "campaign-ranking-by-filter",
    path: "ranking-by-filter",
    meta: ranking_45by_45filterZ3diDouRRHMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-filter.vue")
  },
  {
    name: "campaign-ranking-by-question",
    path: "ranking-by-question",
    meta: ranking_45by_45questionEbD0nKcyyCMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-question.vue")
  },
  {
    name: "campaign-resume",
    path: "resume",
    meta: resumei2jcsv9i7SMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/[[campaign]]/resume.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexcJ4pV2zNUiMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/index.vue")
  },
  {
    name: "inside-consultant-employee",
    path: "/inside-consultant-employee",
    meta: inside_45consultant_45employeerp0KUF7ytcMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/inside-consultant-employee.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logincqAuUBOzmGMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/login.vue")
  },
  {
    name: "my-surveys",
    path: "/my-surveys",
    meta: my_45surveysgRtgQ19HYoMeta || {},
    component: () => import("/codebuild/output/src633718688/src/dashboard.app.winx.ai/pages/my-surveys.vue")
  }
]