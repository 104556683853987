const ptBr = {
  card: `
    <div>
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-6 text-center font-batusa">
        Dissonância
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        A dissonância nas organizações surge quando há uma desconexão entre os valores declarados pela empresa e seu comportamento real. Em outras palavras, a empresa pode afirmar certos princípios, mas suas ações mostram algo diferente.
      </p>
    </div>`,
  title: `Dissonância`,
  subTitle: `Valores Atuais e Cultura Atual`,
  _sectionTitle: {
    perceived: `Cultura Percebida`,
    current: `Cultura Atual`,
  },
};

const es = {
  card: `
    <div>
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-6 text-center font-batusa">
        Disonancia
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        La disonancia en las organizaciones surge cuando hay una desconexión entre los valores declarados por la empresa y su comportamiento real. En otras palabras, la empresa puede afirmar ciertos principios, pero sus acciones muestran algo diferente.
      </p>
    </div>`,
  title: `Disonancia`,
  subTitle: `Valores Actuales y Cultura Actual`,
  _sectionTitle: {
    perceived: `Cultura Percibida`,
    current: `Cultura Actual`,
  },
};

const en = {
  card: `
    <div>
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-6 text-center font-batusa">
        Dissonance
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        Dissonance in organizations arises when there is a disconnect between the declared values of the company and its actual behavior. In other words, the company may claim certain principles, but its actions show something different.
      </p>
    </div>`,
  title: `Dissonance`,
  subTitle: `Current Values and Current Culture`,
  _sectionTitle: {
    perceived: `Perceived Culture`,
    current: `Current Culture`,
  },
};

export default {
  key: 'dissonance',
  translations: {
    ptBr,
    es,
    en,
  },
};
