const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mt-2 mb-4 underline decoration-4 underline-offset-8 decoration-[#B2DDF2] font-batusa">
      Clusters de Engajamento
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      O cluster de engajamento indica onde cada membro da sua equipe se encaixa no mapa comportamental. Classificamos os colaboradores em 4 grupos:
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Totalmente Engajados</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-200">Contribuidores Chave</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Grupo de Oportunidade</span> e
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Totalmente Desengajados</span>.
    </p>
    `,
  title: `Clusters de Engajamento`,
  contributor: `Contribuidores Chave`,
  opportunity: `Grupo de Oportunidade`,
  _ranking: {
    engaged: `Totalmente Engajados`,
    partiallyEngaged: `Contribuidores Chave`,
    partiallyDisengaged: `Grupo de Oportunidade`,
    disengaged: `Totalmente Desengajados`,
  },
  _tooltip: {
    engaged:
      'Um colaborador totalmente engajado está sempre aprendendo, assume riscos calculados e se sente desafiado em suas tarefas. Ele tem orgulho do seu trabalho e, apesar do estresse, encontra gratificação no que faz, pois ama sua profissão.',
    contributor:
      'Um colaborador contribuidor chave atende às expectativas principais e é focado no que já domina. Ele assume poucos riscos e atende bem às demandas dos líderes. Raramente se sente desafiado, mas mantém um compromisso com seu emprego e carreira.',
    opportunity:
      'Um colaborador do grupo de oportunidade sente-se subutilizado, prioriza questões pessoais e entrega o mínimo necessário. Focado na remuneração, ele considera o relógio sua principal métrica de trabalho.',
    disengaged:
      'Um colaborador totalmente desengajado está entediado e frustrado, usando sarcasmo sobre suas entregas. Ele fala mal da empresa e dos líderes, culpa os outros e desistiu de se engajar.',
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-2 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
      Grupos de participación
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      El grupo de participación indica dónde encaja cada miembro del equipo dentro del mapa de comportamiento. Clasificamos a los empleados en 4 grupos:
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Totalmente Comprometido</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-200">Contribuyentes Clave</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Grupos de Oportunidad</span> y
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Totalmente Desconectado</span>.
    </p>
    `,
  title: `Grupos de participación`,
  contributor: `Contribuidores Clave`,
  opportunity: `Grupo de Oportunidad`,
  _ranking: {
    engaged: `Totalmente Comprometido`,
    partiallyEngaged: `Contribuyentes Clave`,
    partiallyDisengaged: `Grupos de Oportunidad`,
    disengaged: `Totalmente Desconectado`,
  },
  _tooltip: {
    engaged:
      'Un empleado totalmente comprometido está siempre aprendiendo, asume riesgos calculados y se siente desafiado en sus tareas. Se enorgullece de su trabajo y, a pesar del estrés, encuentra gratificación en lo que hace porque ama su profesión.',
    contributor:
      'Un colaborador clave cumple con las principales expectativas y se enfoca en lo que ya domina. Asume pocos riesgos y responde bien a las demandas de sus líderes. Raramente se siente desafiado, pero mantiene un compromiso con su trabajo y su carrera.',
    opportunity:
      'Un empleado del grupo de oportunidad se siente infrautilizado, prioriza asuntos personales y entrega lo mínimo necesario. Enfocado en la remuneración, considera el reloj como su principal métrica de trabajo.',
    disengaged:
      'Un empleado completamente desvinculado está aburrido y frustrado, usando sarcasmo sobre sus entregas. Habla mal de la empresa y de los líderes, culpa a otros y ha renunciado a comprometerse.',
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-2 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
      Engagement Clusters
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      The engagement cluster indicates where each team member fits within the behavioral map. We classify employees into 4 groups:
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Fully Engaged</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-200">Key Contributors</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Opportunity Groups</span> and
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Totally Disengaged</span>.
    </p>
    `,
  title: `Engagement Clusters`,
  contributor: `Key Contributors`,
  opportunity: `Opportunity Group`,
  _ranking: {
    engaged: `Fully Engaged`,
    partiallyEngaged: `Key Contributors`,
    partiallyDisengaged: `Opportunity Groups`,
    disengaged: `Totally Disengaged`,
  },
  _tooltip: {
    engaged:
      'A fully engaged employee is always learning, takes calculated risks, and feels challenged in their tasks. They take pride in their work and, despite the stress, find gratification in what they do because they love their profession.',
    contributor:
      'A key contributor meets the main expectations and focuses on what they already master. They take few risks and effectively meet the demands of their leaders. They rarely feel challenged but maintain a commitment to their job and career.',
    opportunity:
      'An opportunity group employee feels underutilized, prioritizes personal matters, and delivers the bare minimum. Focused on compensation, they consider the clock as their main work metric.',
    disengaged:
      'A totally disengaged employee is bored and frustrated, using sarcasm about their deliverables. They speak poorly of the company and its leaders, blame others, and have given up on engaging.',
  },
};

export default {
  key: 'engagementClusters',
  translations: {
    ptBr,
    es,
    en,
  },
};
