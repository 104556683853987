const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-yellow-400 text-center mb-6 lg:mb-0 font-batusa">
      Perguntas Customizadas
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Os resultados a seguir são baseados em
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">perguntas customizadas</span>
      pela empresa. Esses dados são fundamentais para entender a percepção dos colaboradores e identificar áreas de melhoria em
      situações específicas da empresa. A partir disso, a gestão pode tomar decisões informadas para aprimorar o ambiente de trabalho,
      o que beneficia tanto a equipe quanto a empresa.
    </p>`,
  title: 'Perguntas Customizadas',
  response: 'Distribuição de respostas'
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 lg:mb-0 font-batusa">
      Preguntas Personalizadas
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Los siguientes resultados se basan en
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">preguntas personalizadas</span>
      realizadas por la empresa. Estos datos son fundamentales para comprender la percepción de los empleados e identificar áreas de mejora en situaciones específicas de la empresa. A partir de esto, la gestión puede tomar decisiones informadas para mejorar el ambiente laboral, lo que beneficia tanto al equipo como a la empresa.
    </p>`,
  title: 'Preguntas Personalizadas',
  response: 'Distribución de respuestas'
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 lg:mb-0 font-batusa">
      Custom Questions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      The following results are based on
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">custom questions</span>
      created by company. This data is essential for understanding employee perceptions and identifying areas for improvement in specific company situations. From this, management can make informed decisions to enhance the work environment, benefitting both the team and the company.
    </p>`,
  title: 'Custom Questions',
  response: 'Distribution of responses'
};

export default {
  key: 'customizedQuestions',
  translations: {
    ptBr,
    es,
    en,
  },
};
