const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 font-batusa">
      Tipos de Cultura
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Com base nas respostas, identificamos a
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">Cultura Atual</span> e a
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-teal-400">Cultura Desejada</span>
      da organização, divididas em 4 tipos. Esta análise nos permite avaliar a congruência entre as expectativas dos colaboradores e a prática cotidiana.
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">1.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">Colaborar e co-criar</span>
        (colaboração, cooperação e trabalho em equipe).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">2.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-orange-400">Inovar e empreender</span>
        (imaginação, criatividade, inovação e empreendedorismo).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">3.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">Vender e competir</span>
        (clientes, vendas e geração de resultados).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">4.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">Planejar e controlar</span>
        (clareza de hierarquia, eficiência, redução de custos e melhoria contínua).
      </p>
    </p>`,
  _aspirational: {
    title: 'Cultura Desejada',
  },
  _current: {
    title: 'Cultura Atual',
  },
  title: `Tipos de Cultura`,
  subTitle: `Cultura Atual e Cultura Desejada`,
  sectionTitle: `Perguntas - Cultura Percebida`,
  inovate: `Inovar e Empreender`,
  sale: `Vender e Competir`,
  control: `Planejar e Controlar`,
  collaborate: `Colaborar e Co-Criar`,
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 font-batusa">
      Tipos de Cultura
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Con base en las respuestas, identificamos la
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">Cultura Actual</span> y la
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-teal-400">Cultura Deseada</span>
      de la organización, divididas en 4 tipos. Este análisis nos permite evaluar la congruencia entre las expectativas de los colaboradores y la práctica cotidiana.
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">1.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">Colaborar y co-crear</span>
        (colaboración, cooperación y trabajo en equipo).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">2.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-orange-400">Innovar y emprender</span>
        (imaginación, creatividad, innovación y emprendimiento).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">3.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">Vender y competir</span>
        (clientes, ventas y generación de resultados).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">4.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">Planificar y controlar</span>
        (claridad de jerarquía, eficiencia, reducción de costos y mejora continua).
      </p>
    </p>`,
  _aspirational: {
    title: 'Cultura Deseada',
  },
  _current: {
    title: 'Cultura Actual',
  },
  title: `Tipos de Cultura`,
  subTitle: `Cultura Actual y Cultura Deseada`,
  sectionTitle: `Perguntas - Cultura Percebida`,
  inovate: `Innovar y Emprender`,
  sale: `Vender y Competir`,
  control: `Planificar y Controlar`,
  collaborate: `Colaborar y Co-Crear`,
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 font-batusa">
      Types of Culture
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Based on the responses, we identified the company's
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">Current Culture</span> and
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-teal-400">Desired Culture</span>,
      divided into four types. This analysis allows us to evaluate the congruence between employee expectations and daily practice.
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">1.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">Collaborate and co-create</span>
        (collaboration, cooperation, and teamwork).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">2.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-orange-400">Innovate and undertake</span>
        (imagination, creativity, innovation, and entrepreneurship).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">3.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">Sell and compete</span>
        (customers, sales, and results generating).
      </p>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        <span class="font-bold">4.</span>
        <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">Plan and control</span>
        (clarity of hierarchy, efficiency, cost reduction, and continuous improvement).
      </p>
    </p>`,
  _aspirational: {
    title: 'Desired Culture',
  },
  _current: {
    title: 'Current Culture',
  },
  title: `Types of Culture`,
  subTitle: `Current Culture and Desired Culture`,
  sectionTitle: `Questions - Perceived Culture`,
  inovate: `Innovate and Entrepreneurship`,
  sale: `Sell and Compete`,
  control: `Plan and Control`,
  collaborate: `Collaborate and Co-Create`,
};

export default {
  key: 'cultureTypes',
  translations: {
    ptBr,
    es,
    en,
  },
};
