const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
      Dimensões de Engajamento
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      As dimensões de engajamento envolvem a conexão dos colaboradores com o
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">trabalho realizado</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">suas equipes</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">seus líderes</span>
      e a <span class="underline decoration-2 underline-offset-4 decoration-white-400">própria organização</span>.
      Elas indicam de onde vem a maior parte do engajamento dos colaboradores.
    </p>`,
  title: `Dimensões de Engajamento`,
  _work: {
    title: `Meu Trabalho`,
    message: `
      As atividades de trabalho promovem maior engajamento quando o colaborador percebe que seu esforço é recompensado.
      Essa gratificação está ligada ao acesso a recursos e ferramentas, ao progresso pessoal alcançado e ao entusiasmo em enfrentar novos desafios.
    `,
  },
  _manager: {
    title: `Meu Gestor`,
    message: `O gestor aumenta o engajamento ao oferecer suporte, reconhecimento e oportunidades de crescimento. Comunicação clara, feedback construtivo e incentivo à autonomia motivam o colaborador e fortalecem seu compromisso com a organização.`,
  },
  _team: {
    title: `Minha Equipe`,
    message: `A equipe de um colaborador pode aumentar seu engajamento ao criar um ambiente de apoio e colaboração. Quando há compartilhamento de conhecimentos, ajuda mútua e celebração de sucessos, o colaborador se sente valorizado e motivado. A confiança e a sinergia na equipe fortalecem o senso de pertencimento e dedicação ao trabalho.`,
  },
  _organization: {
    title: `Minha Organização`,
    message: `A empresa pode aumentar o engajamento dos colaboradores ao promover uma cultura positiva, oferecer oportunidades de crescimento e reconhecer desempenho. Benefícios atraentes e um ambiente inclusivo reforçam a motivação. Clareza na missão e nos valores da empresa também fortalece a conexão e o compromisso dos colaboradores.`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
      Dimensiones de Compromiso
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Las dimensiones de compromiso implican la conexión de los empleados con el
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">trabajo realizado</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">sus equipos</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">sus líderes</span> y la
      <span class="underline decoration-2 underline-offset-4 decoration-white">propia organización</span>
      Indican de dónde proviene la mayor parte del compromiso de los empleados.
    </p>`,
  title: `Dimensiones de Compromiso`,
  _work: {
    title: `Mi Trabajo`,
    message: `
      Las actividades laborales promueven un mayor compromiso cuando el empleado percibe que su esfuerzo es recompensado.
      Esta gratificación está ligada al acceso a recursos y herramientas, al progreso personal alcanzado y al entusiasmo por enfrentar nuevos desafíos.
    `,
  },
  _manager: {
    title: `Mi Gestor`,
    message: `El gestor incrementa el compromiso al ofrecer apoyo, reconocimiento y oportunidades de crecimiento. La comunicación clara, la feedback constructivo y el fomento de la autonomía motivan al colaborador y refuerzan su compromiso con la organización.`,
  },
  _team: {
    title: `Mi Equipo`,
    message: `El equipo de un colaborador puede aumentar su compromiso al crear un ambiente de apoyo y colaboración. Cuando hay compartir de conocimientos, ayuda mutua y celebración de éxitos, el colaborador se siente valorado y motivado. La confianza y la sinergia en el equipo fortalecen el sentido de pertenencia y la dedicación al trabajo.`,
  },
  _organization: {
    title: `Mi Organización`,
    message: `La empresa puede aumentar el compromiso de los empleados al promover una cultura positiva, ofrecer oportunidades de crecimiento y reconocer el desempeño. Beneficios atractivos y un entorno inclusivo refuerzan la motivación. La claridad en la misión y los valores de la empresa también fortalece la conexión y el compromiso de los empleados.`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
      Engagement Dimensions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      The dimensions of engagement involve the connection of employees with their
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">work performed</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">their teams</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">their leaders</span> and the
      <span class="underline decoration-2 underline-offset-4 decoration-white">organization itself</span>.
      They indicate where the majority of employee engagement comes from.
    </p>`,
  title: `Engagement Dimensions`,
  _work: {
    title: `My Work`,
    message: `
      Work activities promote greater engagement when the employee perceives that their effort is rewarded.
      This gratification is linked to access to resources and tools, personal progress achieved, and enthusiasm in facing new challenges.
    `,
  },
  _manager: {
    title: `My Manager`,
    message: `The manager increases engagement by providing support, recognition, and growth opportunities. Clear communication, constructive feedback, and encouragement of autonomy motivate the employee and strengthen their commitment to the organization.`,
  },
  _team: {
    title: `My Team`,
    message: `An employee’s team can increase their engagement by creating a supportive and collaborative environment. When there is knowledge sharing, mutual help, and celebration of successes, the employee feels valued and motivated. Trust and synergy within the team strengthen the sense of belonging and dedication to work.`,
  },
  _organization: {
    title: `My Organization`,
    message: `The company can increase employee engagement by promoting a positive culture, offering growth opportunities, and recognizing performance. Attractive benefits and an inclusive environment enhance motivation. Clarity in the company's mission and values also strengthens employee connection and commitment.`,
  },
};

export default {
  key: 'engagementDimension',
  translations: {
    ptBr,
    es,
    en,
  },
};
