const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline underline-offset-8 decoration-4 decoration-yellow-400 font-batusa">
      Ranking por Filtros
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Visualizar resultados por filtros é essencial para identificar padrões específicos, segmentar dados por departamento ou equipe e obter insights acionáveis. Esses insights ajudam a corrigir problemas, promovem melhorias contínuas e personalizam estratégias para diferentes áreas da empresa.
    </p>`,
  title: `Ranking por Filtros`,
  subTitle: 'Filtre por pergunta'
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline underline-offset-8 decoration-4 decoration-yellow-400 font-batusa">
      Clasificación por Filtros
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Ver resultados por filtros es esencial para identificar patrones específicos, segmentar datos por departamento o equipo y obtener información útil. Estos conocimientos ayudan a solucionar problemas, promover mejoras continuas y personalizar estrategias para diferentes áreas de la empresa.
    </p>`,
  title: `Clasificación por Filtros`,
  subTitle: 'Filtrar por pregunta',
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline underline-offset-8 decoration-4 decoration-yellow-400 font-batusa">
      Ranking by Filters
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Visualizing results by filters is essential for identifying specific patterns, segmenting data by department or team, and gaining actionable insights. These insights help fix issues, drive continuous improvement, and tailor strategies for different areas of the business.
    </p>`,
  title: `Ranking by Filters`,
  subTitle: 'Filter by question',
};

export default {
  key: 'rankingByFilter',
  translations: {
    ptBr,
    es,
    en,
  },
};
