const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">
      Ranking por Perguntas
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Visualizar o ranking dos resultados das perguntas é crucial para identificar padrões, segmentar dados por questões específicas e planejar ações de melhoria de forma eficaz.
    </p>`,
  title: `Ranking por Perguntas`,
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">
      Clasificación por Preguntas
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Visualizar la clasificación de los resultados de las preguntas es crucial para identificar patrones, segmentar datos por cuestiones específicas y planificar acciones de mejora de forma eficaz.
    </p>`,
  title: `Clasificación por Preguntas `,
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">
      Ranking by Questions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Viewing the ranking of question results is crucial for identifying patterns, segmenting data by specific issues, and effectively planning improvement actions.
    </p>`,
  title: `Ranking by Questions`,
};

export default {
  key: 'rankingByQuestion',
  translations: {
    ptBr,
    es,
    en,
  },
};
