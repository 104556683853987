const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-8 underline underline-offset-8 decoration-4 decoration-teal-400 font-batusa">
      Elementos da Cultura e da Estratégia
    </h3>
    <p class="dark:text-muted-200 text-muted-800 text-left">
      Os elementos da cultura e da estratégia são cruciais para o sucesso e o engajamento dos colaboradores. Os resultados indicam se os integrantes da empresa estão alinhados com o
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">propósito</span> e os
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">valores</span>,
      que compõem a cultura, bem como com a
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">missão</span> e a
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-orange-400">visão</span>, enquanto elementos da estratégia.
    </p>
  `,
  title: 'Elementos da Cultura e da Estratégia',
  _purpose: {
    title: `Propósito`,
    message: `O propósito é a força motriz. Mais do que apenas lucros, o propósito almeja a transformação e o bem-estar coletivo, definindo a razão pela qual a empresa existe e o impacto que causa nas pessoas interessadas.`,
  },
  _mission: {
    title: `Missão`,
    message: `A missão oferece um direcionamento claro das prioridades da organização no seu atual ciclo estratégico, com clareza de o que é preciso fazer agora.`,
  },
  _vision: {
    title: `Visão`,
    message: `A visão é uma representação do futuro desejado para a empresa. Ela explicita uma imagem nítida de um futuro inspirador, aspiracional e permeado por sonho grande.`,
  },
  _values: {
    title: `Valores`,
    message: `Os valores são os princípios fundamentais que guiam o comportamento e as decisões. Eles expressam o que é importante e inegociável, refletindo a essência da cultura organizacional. Além de orientar as ações internas, os valores também servem como um diferencial competitivo, atraindo clientes, parceiros e talentos que compartilham das mesmas crenças e princípios.`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-8 underline underline-offset-8 decoration-4 decoration-teal-400 font-batusa">
      Elementos de la Cultura y de la Estrategia
    </h3>
    <p class="dark:text-muted-200 text-muted-800 text-left">
      Los elementos de la cultura y la estrategia son cruciales para el éxito y el compromiso de los colaboradores. Los resultados indican si los integrantes de la empresa están alineados con el
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">propósito</span> y los
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">valores</span>,
      que componen la cultura, así como con la
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">misión</span> y la
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-orange-400">visión</span>, como elementos de la estrategia.
    </p>
  `,
  title: 'Elementos de la Cultura y de la Estrategia',
  _purpose: {
    title: `Propósito`,
    message: `El propósito es la fuerza motriz. Más que solo beneficios, el propósito aspira a la transformación y al bienestar colectivo, definiendo la razón por la cual la empresa existe y el impacto que causa en las personas interesadas.`,
  },
  _mission: {
    title: `Misión`,
    message: `La misión ofrece una dirección clara de las prioridades de la organización en su ciclo estratégico actual, con claridad sobre lo que se debe hacer ahora.`,
  },
  _vision: {
    title: `Visión`,
    message: `La visión es una representación del futuro deseado para la empresa. Explicita una imagen nítida de un futuro inspirador, aspiracional y lleno de grandes sueños.`,
  },
  _values: {
    title: `Valores`,
    message: `Los valores son los principios fundamentales que guían el comportamiento y las decisiones. Expresan lo que es importante y innegociable, reflejando la esencia de la cultura organizacional. Además de orientar las acciones internas, los valores también sirven como un diferenciador competitivo, atrayendo a clientes, socios y talentos que comparten las mismas creencias y principios.`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-8 underline underline-offset-8 decoration-4 decoration-teal-400 font-batusa">
      Cultural and Strategic Elements
    </h3>
    <p class="dark:text-muted-200 text-muted-800 text-left">
      The elements of culture and strategy are crucial for success and employee engagement. The results indicate whether the company members are aligned with the
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-yellow-400">purpose</span> and
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-sky-200">values</span>,
      which make up the culture, and with the
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-teal-400">mission</span> and
      <span class="font-semibold underline decoration-2 underline-offset-4 decoration-orange-400">vision</span>, which are elements of strategy.
    </p>
  `,
  title: 'Cultural and Strategic Elements',
  _purpose: {
    title: `Purpose`,
    message: `The purpose is the driving force. More than just profits, the purpose aims for transformation and collective well-being, defining why the company exists and its impact on stakeholders.`,
  },
  _mission: {
    title: `Mission`,
    message: `The mission provides clear direction for the organization's priorities in its current strategic cycle, with clarity on what needs to be done now.`,
  },
  _vision: {
    title: `Vision`,
    message: `The vision represents the company's desired future. It outlines a clear image of an inspiring, aspirational future filled with big dreams.`,
  },
  _values: {
    title: `Values`,
    message: `Values are the fundamental principles that guide behavior and decision-making. They express what is important and non-negotiable, reflecting the essence of the organizational culture. In addition to guiding internal actions, values also serve as a competitive differentiator, attracting customers, partners, and talents who share the same beliefs and principles.`,
  },
};

export default {
  key: 'cultureElements',
  translations: {
    ptBr,
    es,
    en,
  },
};
