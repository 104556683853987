import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import auth_client_rVZYbhDUOf from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/winx-auth/dist/runtime/plugins/auth.client.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_UYiXMU8ZyN from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_m51yvfcbqo from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_42RIGIx6e8 from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@nuxt/image/dist/runtime/plugin.js";
import sentry_client_VdOeuw25Mb from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/.nuxt/sentry-client-config.mjs";
import auth_redirect_eeiDDMMT7n from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/winx-auth/dist/runtime/plugins/auth-redirect.js";
import core_mKO01y0X7r from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/winx-base/plugins/core.ts";
import directives_1uFdALNymg from "/codebuild/output/src633718688/src/dashboard.app.winx.ai/node_modules/winx-base/plugins/directives.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  auth_client_rVZYbhDUOf,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_client_OVoKJro5pc,
  plugin_client_UYiXMU8ZyN,
  plugin_m51yvfcbqo,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_42RIGIx6e8,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  auth_redirect_eeiDDMMT7n,
  core_mKO01y0X7r,
  directives_1uFdALNymg
]