const ptBr = {
  title: 'Consultor Inside - Colaborador',
  subtitle: 'Segue abaixo a devolutiva da pesquisa que você participou com a empresa',
  _sectionTitle: {
    title: 'Boosterh',
    message:
      'O Boosterh deve ser utilizado como uma ferramenta para guiar seus próximos passos, ela te oferece recomendações após uma análise dos seus resultados da pesquisa realizada com a empresa',
    feedback: 'Devolutiva de ',
  },
};

const es = {
  title: 'Consultor Inside - Colaborador',
  subtitle: 'A continuación se presenta la retroalimentación de la encuesta en la que participaste con la empresa',
  _sectionTitle: {
    title: 'Boosterh',
    message:
      'El Boosterh debe ser utilizado como una herramienta para guiar tus próximos pasos, ofreciendo recomendaciones tras analizar los resultados de tu encuesta con la empresa',
    feedback: 'Retroalimentación de ',
  },
};

const en = {
  title: 'Inside Consultant - Collaborator',
  subtitle: 'Below is the feedback from the survey you participated in with the company',
  _sectionTitle: {
    title: 'Boosterh',
    message:
      'The Boosterh should be used as a tool to guide your next steps, offering recommendations after analyzing your survey results with the company',
    feedback: 'Feedback from ',
  },
};

export default {
  key: 'insideConsultantEmployee',
  translations: {
    ptBr,
    es,
    en,
  },
};
