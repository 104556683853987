const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-blue-300 mb-6 lg:mb-0 font-batusa">
      Dimensões
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      As <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">dimensões</span>
      são as diferentes áreas ou aspectos que foram analisados na pesquisa, fornecendo uma
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">visão geral</span>
      dos tópicos investigados para facilitar a compreensão e o acompanhamento dos resultados obtidos nas diversas dimensões avaliadas.
    </p>
  `,
  title: 'Dimensões',
  subTitle: 'Lista de Perguntas',
  label: 'Dimensão',
  _tables: {
    reward: 'Recompensa',
    development: 'Desenvolvimento',
    admission: 'Admissão',
    integration: 'Integração',
    career: 'Carreira',
    performanceevaluation: `Avaliação de Desempenho`,
    evaluation: `Avaliação de Desempenho`,
    training: 'Treinamento',
    communication: 'Comunicação',
    resignation: 'Renúncia',
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-blue-300 mb-6 lg:mb-0 font-batusa">
      Dimensiones
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Las <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">dimensiones</span>
      son las diferentes áreas o aspectos que fueron analizados en la investigación, proporcionando una
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">visión general</span>
      de los temas investigados para facilitar la comprensión y el seguimiento de los resultados obtenidos en las diversas dimensiones evaluadas.
    <p/>
  `,
  title: 'Dimensiones',
  subTitle: 'Lista de Preguntas',
  label: 'Dimensión',
  _tables: {
    reward: 'Recompensa',
    development: 'Desarrollo',
    admission: 'Admisión',
    integration: 'Integración',
    career: 'Carrera',
    performanceevaluation: `Evaluación de Desempeño`,
    evaluation: `Evaluación de Desempeño`,
    training: 'Entrenamiento',
    communication: 'Comunicación',
    resignation: 'Renuncia',
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline text-center decoration-4 underline-offset-8 decoration-blue-300 mb-6 lg:mb-0 font-batusa">
      Dimensions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      The <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">dimensions</span>
      are the different areas or aspects analyzed in the research, providing an
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">overview</span>
      of the topics investigated to facilitate the understanding and tracking of the results obtained in the various dimensions evaluated.
    </p>
  `,
  title: 'Dimensions',
  subTitle: 'List of Questions',
  label: 'Dimension',
  _tables: {
    reward: 'Reward',
    development: 'Development',
    admission: 'Admission',
    integration: 'Integration',
    career: 'Career',
    performanceevaluation: `Performance Evaluation`,
    evaluation: `Performance Evaluation`,
    training: 'Training',
    communication: 'Communication',
    resignation: 'Resignation',
  },
};

export default {
  key: 'dimension',
  translations: {
    ptBr,
    es,
    en,
  },
};
