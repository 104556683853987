const ptBr = {
  card: `
    <div>
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal mb-3 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
        Flow
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        O estado de fluxo (ou FLOW) é uma experiência de imersão total em uma atividade, onde a pessoa se sente focada, no controle e com autoestima elevada. Durante esse estado, ela perde a noção do tempo e das preocupações, sentindo clareza, satisfação e prazer. Tarefas desafiadoras, mas manejáveis, proporcionam um senso de propósito significativo e aumentam o bem-estar e a satisfação no trabalho.
      </p>
    </div>`,
  title: `Flow`,
  subTitle: `Flow por Filtro`,
  _sectionTitle: {
    inFlow: `Em Flow`,
    enteringFlow: `Entrando no Flow`,
    outFlow: `Fora do Flow`,
  },
};

const es = {
  card: `
    <div>
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal mb-3 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
        Flow
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        El estado de flujo (o FLOW) es una experiencia de inmersión total en una actividad, donde una persona se siente enfocada, en control y con una autoestima elevada. Durante este estado, pierde la noción del tiempo y las preocupaciones, sintiendo claridad, satisfacción y placer. Las tareas desafiantes pero manejables proporcionan un sentido significativo de propósito y aumentan el bienestar y la satisfacción en el trabajo.
      </p>
    </div>`,
  title: `Flow`,
  subTitle: `Flujo por Filtro`,
  _sectionTitle: {
    inFlow: `En Flow`,
    enteringFlow: `Entrando en Flow`,
    outFlow: `Fuera de Flow`,
  },
};

const en = {
  card: `
    <div>
      <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal mb-3 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
        Flow
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
        The flow state is an experience of total immersion in an activity, where a person feels focused, in control, and has elevated self-esteem. During this state, they lose track of time and worries, feeling clarity, satisfaction, and pleasure. Challenging but manageable tasks provide a significant sense of purpose and enhance well-being and job satisfaction.
      </p>
    </div>`,
  title: `Flow`,
  subTitle: `Flow by Filter`,
  _sectionTitle: {
    inFlow: `In Flow`,
    enteringFlow: `Entering Flow`,
    outFlow: `Out of Flow`,
  },
};

export default {
  key: 'flow',
  translations: {
    ptBr,
    es,
    en,
  },
};
