const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
      Participação
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
      Visualizar a participação na pesquisa de modo consolidado permite ver os dados gerais de toda a empresa. Além disso, você consegue filtrar os resultados, possibilitando uma análise detalhada da adesão em cada uma das dimensões da organização.
    </p>`,
  title: `Participação`,
  sectionTitle: `Participação Geral`,
  _tables: {
    filter: `Filtro`,
    participation: `Participação`,
    respondent: 'Respondentes',
    collaborator: 'Colaboradores',
    participant: 'Participantes',
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
      Participación
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
      Visualizar la participación en la encuesta de manera consolidada permite ver los datos generales de toda la empresa. Además, puedes filtrar los resultados, posibilitando un análisis detallado de la adhesión en cada una de las dimensiones de la organización.
    </p>`,
  title: `Participación`,
  sectionTitle: `Participación General`,
  _tables: {
    filter: `Filtro`,
    participation: `Participación`,
    respondent: 'Respondentes',
    collaborator: 'Colaboradores',
    participant: 'Participantes',
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">
      Participation
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
      Viewing the survey participation consolidated allows you to see the overall data of the entire company. Additionally, you can filter the results, enabling a detailed analysis of the adherence in each organization's dimensions.
    </p>`,
  title: `Participation`,
  sectionTitle: `General Participation`,
  _tables: {
    filter: `Filter`,
    participation: `Participation`,
    respondent: 'Respondents',
    collaborator: 'Collaborators',
    participant: 'Participants',
  },
};

export default {
  key: 'adhesion',
  translations: {
    ptBr,
    es,
    en,
  },
};
