const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
      Comentários
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Esse espaço é dedicado a mostrar os comentários dos respondentes. Estes comentários são complementares às respostas fornecidas em perguntas de
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">escala Likert</span>
      ou <span class="underline decoration-2 underline-offset-4 decoration-sky-200">perguntas fechadas</span>, oferecendo um entendimento mais
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">profundo e detalhado</span>
      sobre as percepções dos colaboradores.
    </p>`,
  _buttons: {
    update: 'Quero ver meus Destaques',
  },
  _response: {
    title: `Comentários`,
    subTitle: `Lista de respostas`,
    label: `respostas`,
  },
  _highlight: {
    card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-teal-400 text-center mb-6 lg:mb-0 font-batusa">
      Destaques da Winnie
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Vou extrair os <span class="underline decoration-2 underline-offset-4 decoration-teal-400">temas principais</span>
      dos comentários dos seus colaboradores, permitindo que você compreenda os
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">tópicos mais recorrentes</span>
      e acelere o planejamento de ações de melhoria. Escolha uma pergunta e veja os destaques que selecionei!
    </p>`,
    request: 'Solicitação',
    complaint: 'Reclamação',
    problem: 'Problema',
    compliment: 'Elogio',
    others: 'Outros',
    search: 'Os Destaques estão carregando, aguarde um momento...',
    title: 'Destaques da Winnie',
  },
  _message: {
    title: 'Estou trabalhando nos seus destaques!',
    subtitle: 'Quando eu acabar, você receberá uma notificação por email. Aguarde um momento!',
    _insufficient: {
      title: 'Ops, o valor mínimo de comentários ainda não foi atingido.',
      subtitle: 'Volte em breve para conferir os resultados!',
    },
  },
  word: `Palavra-chave`,
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
      Comentarios
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Este espacio está dedicado a mostrar los comentarios de los encuestados. Estos comentarios complementan las respuestas proporcionadas en las preguntas de
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">escala Likert</span>
      o <span class="underline decoration-2 underline-offset-4 decoration-sky-200">preguntas cerradas</span>, ofreciendo una comprensión más
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">profunda y detallada</span>
      sobre las percepciones de los colaboradores.
    </p>`,
  _buttons: {
    update: '¡Quiero ver mis Destacados',
  },
  _response: {
    title: `Comentarios`,
    subTitle: `Lista de Respuestas`,
    label: `respuestas`,
  },
  _highlight: {
    card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-teal-400 text-center mb-6 lg:mb-0 font-batusa">
      Lo más destacado de Winnie
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Voy a extraer los <span class="underline decoration-2 underline-offset-4 decoration-teal-400">temas principales</span>
      de los comentarios de sus colaboradores, permitiéndole comprender los
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">temas más recurrentes</span>
      y acelerar la planificación de acciones de mejora. ¡Elija una pregunta y vea los aspectos destacados que he seleccionado!
    </p>`,
    request: 'Solicitud',
    complaint: 'Queja',
    problem: 'Problema',
    others: 'Otros',
    search: 'Buscando Destacados, espere un momento...',
    title: 'Destacados de Winnie',
  },
  _message: {
    title: 'Estoy trabajando en tus aspectos más destacados!',
    subtitle: 'Cuando termine, recibirás una notificación por correo electrónico. ¡Espera un momento!',
    _insufficient: {
      title: 'Vaya, aún no se ha alcanzado el número mínimo de comentarios.',
      subtitle: '¡Vuelve pronto para comprobar los resultados!',
    },
  },
  word: `Palabra Clave`,
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 text-center mb-6 lg:mb-0 font-batusa">
      Comments
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      This space is dedicated to showcasing the respondents' comments. These comments complement the answers in the
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">Likert scale</span>
      or <span class="underline decoration-2 underline-offset-4 decoration-sky-200">closed-ended questions</span>, offering a deeper and more
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">detailed understanding</span>
      of the employees' perceptions.
    </p>`,
  _buttons: {
    update: 'I want to see my Highlights',
  },
  _response: {
    title: `Comments`,
    subTitle: `List of Answers`,
    label: `responses`,
  },
  _highlight: {
    card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-teal-400 text-center mb-6 lg:mb-0 font-batusa">
      Winnie's Highlights
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      I will extract the <span class="underline decoration-2 underline-offset-4 decoration-teal-400">main themes</span>
      from your employees' comments, allowing you to understand the
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">most recurring topics</span>
      and accelerate the planning of improvement actions. Choose a question and see the highlights I've selected!
    </p>`,
    request: 'Request',
    complaint: 'Complaint',
    problem: 'Problem',
    compliment: 'Compliment',
    others: 'Others',
    search: 'Searching Highlights, please wait...',
    title: "Winnie's Highlights",
  },
  _message: {
    title: "I'm working on your Highlights!",
    subtitle: "When I'm done, you'll receive an email notification. Please wait a moment!",
    _insufficient: {
      title: 'Oops, the minimum number of comments has not been reached yet.',
      subtitle: `Check back soon to see the results!`,
    },
  },
  word: `Keyword`,
};

export default {
  key: 'comments',
  translations: {
    ptBr,
    es,
    en,
  },
};
