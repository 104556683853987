const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
      <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">eNPS</span>
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
      O <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">eNPS</span>
      (Employee Net Promoter Score) avalia a lealdade dos colaboradores, mostrando o quanto recomendam a empresa como um excelente lugar para trabalhar, divididos em 3 grupos:
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Detratores</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Neutros</span> e
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Promotores</span>.
    </p>`,
  title: `eNPS`,
  critical: 'Zona Crítica',
  improv: 'Zona de Aperfeiçoamento',
  quality: 'Zona de Qualidade',
  excellence: 'Zona Excelência',
  tableResultsTitle: 'Resultados por filtro',
  formula: 'Fórmula',
  less: 'menos',
  _detractors: {
    title: `Detratores`,
  },
  _neutrals: {
    title: `Neutros`,
  },
  _promoters: {
    title: `Promotores`,
  },
  _team: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-teal-400 font-batusa">eNPS da Equipe</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        O <span class="font-semibold underline underline-offset-4 decoration-sky-200 decoration-2">eNPS da equipe</span>
        é uma métrica que mede o nível de satisfação e engajamento dos colaboradores dentro do seu
        <span class="underline decoration-2 underline-offset-4 decoration-teal-200">próprio time</span>.
        Assim como o eNPS da empresa, ele classifica os membros da equipe em Detratores, Neutros e Promotores, com base na probabilidade de recomendarem sua equipe como um ótimo grupo para se trabalhar. Esta avaliação fornece uma visão clara sobre a
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">dinâmica interna da equipe</span>,
        incentivando melhorias contínuas e promovendo um ambiente de trabalho colaborativo e positivo.
      </p>`,
    title: 'eNPS da Equipe',
    label: 'Equipe',
  },
  _leadership: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">eNPS da Liderança</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        O <span class="font-semibold underline underline-offset-4 decoration-teal-400 decoration-2">eNPS da liderança</span>
        mensura a satisfação dos colaboradores especificamente em relação à
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">atuação dos líderes</span>
        da empresa. Ele avalia como os funcionários enxergam a eficácia, suporte e inspiração proveniente da gestão, classificando-os em Detratores, Neutros e Promotores. Este indicador é crucial para identificar
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">áreas de melhoria</span>
        e fortalecer o papel da liderança na promoção de um ambiente de trabalho envolvente e alinhado às expectativas dos colaboradores.
      </p>`,
    title: 'eNPS da Liderança',
    label: 'Liderança',
  },
  _product: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">eNPS do Produto</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        O <span class="font-semibold underline underline-offset-4 decoration-sky-200 decoration-2">eNPS do produto</span>
        da empresa é uma métrica que mede a satisfação e a disposição dos colaboradores
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">em recomendar</span>
        o produto ou serviço que a empresa oferece. Assim como o eNPS geral, os colaboradores são categorizados em Detratores, Neutros e Promotores, indicando o grau de engajamento e confiança que têm no produto. Esta avaliação ajuda a empresa a entender melhor a
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">percepção interna</span>
        sobre a qualidade e eficácia de seu produto, proporcionando insights valiosos para melhorias contínuas.
      </p>`,
    title: 'eNPS do Produto',
    label: 'Produto',
  },
  _company: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">eNPS</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        O <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">eNPS</span>
        (Employee Net Promoter Score) avalia a lealdade dos colaboradores, mostrando o quanto recomendam a empresa como um excelente lugar para trabalhar, divididos em 3 grupos:
        <span class="underline decoration-2 underline-offset-4 decoration-red-400">Detratores</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Neutros</span> e
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Promotores</span>.
      </p>`,
    title: 'eNPS da Empresa',
    label: 'Empresa',
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
      <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">eNPS</span>
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
      El <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">eNPS</span>
      (Employee Net Promoter Score) evalúa la lealtad de los empleados, mostrando cuánto recomiendan la empresa como un excelente lugar para trabajar, divididos en tres grupos:
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Detractores</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Neutrales</span> y
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Promotores</span>.
    </p>`,
  title: `eNPS`,
  critical: 'Zona Crítica',
  improv: 'Zona de Mejora',
  quality: 'Zona de Calidad',
  excellence: 'Zona de Excelencia',
  tableResultsTitle: 'Resultados por filtro',
  formula: 'Fórmula',
  less: 'menos',
  _detractors: {
    title: `Detractores`,
  },
  _neutrals: {
    title: `Neutros`,
  },
  _promoters: {
    title: `Promotores`,
  },
  _team: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-teal-400 font-batusa">eNPS del Equipo</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        El <span class="font-semibold underline underline-offset-4 decoration-sky-200 decoration-2">eNPS del equipo</span>
        es una métrica que mide el nivel de satisfacción y compromiso de los empleados dentro de su
        <span class="underline decoration-2 underline-offset-4 decoration-teal-200">propio equipo</span>.
        Al igual que el eNPS de la empresa, clasifica a los miembros del equipo en Detractores, Neutros y Promotores, en función de la probabilidad de recomendar su equipo como un gran grupo para trabajar. Esta evaluación proporciona una visión clara de la
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">dinámica interna del equipo</span>,
        incentivando mejoras continuas y promoviendo un entorno de trabajo colaborativo y positivo.
      </p>`,
    title: 'eNPS del Equipo',
    label: 'Equipo',
  },
  _leadership: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">eNPS de Liderazgo</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        El <span class="font-semibold underline underline-offset-4 decoration-teal-400 decoration-2">eNPS de liderazgo</span>
        mide la satisfacción de los empleados específicamente en relación con el
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">desempeño de los líderes</span>
        de la empresa. Evalúa cómo los empleados perciben la eficacia, el apoyo y la inspiración proveniente de la gestión, clasificándolos en Detractores, Neutros y Promotores. Este indicador es crucial para identificar
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">áreas de mejora</span>
        y fortalecer el papel del liderazgo en la promoción de un entorno de trabajo envolvente y alineado con las expectativas de los empleados.
      </p>`,
    title: 'eNPS de Liderazgo',
    label: 'Liderazgo',
  },
  _product: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">eNPS del Producto</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        El <span class="font-semibold underline underline-offset-4 decoration-sky-200 decoration-2">eNPS del producto</span>
        eNPS del producto de la empresa es una métrica que mide la satisfacción y la disposición de los empleados
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">para recomendar</span>
        el producto o servicio que ofrece la empresa. Al igual que el eNPS general, los empleados son categorizados como Detractores, Neutros y Promotores, indicando su nivel de compromiso y confianza en el producto. Esta evaluación ayuda a la empresa a comprender mejor las
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">percepciones internas</span>
        sobre la calidad y eficacia de su producto, proporcionando conocimientos valiosos para mejoras continuas.
      </p>`,
    title: 'eNPS del Producto',
    label: 'Producto',
  },
  _company: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">eNPS</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        El <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">eNPS</span>
        (Employee Net Promoter Score) evalúa la lealtad de los empleados, mostrando cuánto recomiendan la empresa como un excelente lugar para trabajar, divididos en tres grupos:
        <span class="underline decoration-2 underline-offset-4 decoration-red-400">Detractores</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Neutros</span> y
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Promotores</span>.
      </p>`,
    title: 'eNPS do Empresa',
    label: 'Empresa',
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
      <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">eNPS</span>
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
      The <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">eNPS</span>
      (Employee Net Promoter Score) evaluates employee loyalty, showing how much they recommend the company as an excellent place to work, divided into three groups:
      <span class="underline decoration-2 underline-offset-4 decoration-red-400">Detractors</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Neutrals</span> and
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Promoters</span>.
    </p>`,
  title: `eNPS`,
  critical: 'Critical Zone',
  improv: 'Improvement Zone',
  quality: 'Quality Zone',
  excellence: 'Excellence Zone',
  tableResultsTitle: 'Results by filter',
  formula: 'Formula',
  less: 'less',
  _detractors: {
    title: `Detractors`,
  },
  _neutrals: {
    title: `Neutrals`,
  },
  _promoters: {
    title: `Promoters`,
  },
  _team: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-teal-400 font-batusa">Team eNPS</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        The <span class="font-semibold underline underline-offset-4 decoration-sky-200 decoration-2">team eNPS</span>
        is a metric that measures the level of satisfaction and engagement of employees within their
        <span class="underline decoration-2 underline-offset-4 decoration-teal-200">own team</span>.
        Similar to the company's eNPS, it categorizes team members as Detractors, Neutrals, and Promoters based on the likelihood of recommending their team as a great group to work in. This evaluation provides a clear insight into the
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">internal dynamics of the team</span>,
        encouraging continuous improvements and promoting a collaborative and positive work environment.
      </p>`,
    title: 'Team eNPS',
    label: 'Team',
  },
  _leadership: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">Leadership eNPS</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        The <span class="font-semibold underline underline-offset-4 decoration-teal-400 decoration-2">leadership eNPS</span>
        measures employees' satisfaction specifically in relation to the
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">performance of the company's leaders</span>.
        It evaluates how employees perceive the effectiveness, support, and inspiration coming from the management, categorizing them as Detractors, Neutrals, and Promoters. This indicator is crucial for identifying
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">areas of improvement</span>
        and strengthening the role of leadership in promoting an engaging work environment aligned with employees' expectations.
      </p>`,
    title: 'Leadership eNPS',
    label: 'Leadership',
  },
  _product: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">Product eNPS</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        The company's <span class="font-semibold underline underline-offset-4 decoration-sky-200 decoration-2">product eNPS</span>
        is a metric that measures employees' satisfaction and their willingness
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">to recommend</span>
        the product or service the company offers. Similar to the general eNPS, employees are categorized as Detractors, Neutrals, and Promoters, indicating their level of engagement and confidence in the product. This evaluation helps the company better understand
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">internal perceptions</span>
        about the quality and effectiveness of its product, providing valuable insights for continuous improvements.
      </p>`,
    title: 'Product eNPS',
    label: 'Product',
  },
  _company: {
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">eNPS</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        The <span class="font-semibold underline underline-offset-4 decoration-orange-400 decoration-2">eNPS</span>
        (Employee Net Promoter Score) evaluates employee loyalty, showing how much they recommend the company as an excellent place to work, divided into three groups:
        <span class="underline decoration-2 underline-offset-4 decoration-red-400">Detractors</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-300">Neutrals</span> and
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Promoters</span>.
      </p>`,
    title: 'Company eNPS',
    label: 'Company',
  },
};

export default {
  key: 'enps',
  translations: {
    ptBr,
    es,
    en,
  },
};
