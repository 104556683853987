const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-medium nui-lead-normal underline decoration-4 underline-offset-8 decoration-yellow-400 text-center mb-6 lg:mb-0 font-batusa">
      Demográficos
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      As
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">perguntas demográficas</span>
      apresentam questões autodeclaradas que foram respondidas pelos participantes. Essas perguntas são usadas para coletar informações demográficas essenciais,
      que ajudam a categorizar e entender melhor os dados da pesquisa,
      proporcionando uma visão mais detalhada e segmentada dos resultados obtidos.
    </p>`,
  title: 'Demográficos',
  response: 'Distribuição de respostas',
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 lg:mb-0 font-batusa">
      Demográficos
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      Las
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">preguntas demográficas</span>
      presentan cuestiones autodeclaradas que fueron respondidas por los participantes.
      Estas preguntas se utilizan para recopilar información demográfica esencial,
       que ayuda a categorizar y comprender mejor los datos de la encuesta,
        proporcionando una visión más detallada y segmentada de los resultados obtenidos.
    </p>`,
  title: 'Demográficos',
  response: 'Distribución de respuestas',
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline text-center decoration-yellow-400 underline-offset-8 decoration-4 mb-6 lg:mb-0 font-batusa">
      Demographics
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
      The
      <span class="underline decoration-2 underline-offset-4 font-bold decoration-orange-400">demographic questions</span>
      present self-reported issues that were answered by the participants.
      These questions are used to collect essential demographic information,
      which helps categorize and better understand the survey data,
      providing a more detailed and segmented view of the obtained results.
    </p>`,
  title: 'Demographics',
  response: 'Distribution of responses',
};

export default {
  key: 'demographics',
  translations: {
    ptBr,
    es,
    en,
  },
};
