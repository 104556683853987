const ptBr = {
  card: `
  <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    Estou aqui para ser sua <span class="underline decoration-2 underline-offset-4 decoration-teal-400">parceira estratégica</span>,
    ajudando você a entender os desafios da empresa e do time a partir da análise de dados.
    Vou te entregar <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">sugestões</span>
    de ações para que você possa planejar políticas de melhoria mais acertadas.
  </p>`,
  title: `Recomendações da Winnie`,
  subTitle: 'Leia a seguir as recomendações do Boosterh para você!',
  sectionTitle: 'Lista de perguntas com baixa favorabilidade',
  sectionTitleTwo: 'Lista de perguntas com favorabilidade abaixo de 70%',
  label: 'Favorabilidade',
  createPlan: 'Criar Plano de Ação',
  seeRecomendation: 'Ver recomendação',
  emptyQuestions: 'Não há perguntas disponíveis no momento.',
  _titles: {
    favorability: 'Favorabilidade da pergunta',
    question: 'Pergunta selecionada',
    deep: 'Aprofundamento',
    importance: 'Importância',
    challenge: 'Desafios',
    objective: 'Objetivos',
    active: 'Atividades',
    task: 'Tarefa',
    preparation: 'Preparação',
    action: 'Ações',
  },
  _data: {
    time: 'Prazo',
    conversation: 'Conversa',
  },
  _buttons: {
    backQuestion: 'Voltar para as Perguntas',
    backObjective: 'Voltar para o Plano de Ação',
    backRecommendation: 'Voltar para a Recomendação',
    backRecommendations: 'Voltar para as Recomendações',
    backDeep: 'Voltar para Aprofundamento',
    backAssignment: 'Voltar para Atribuições',
    backActivity: 'Voltar para Atividades',
    backPlan: 'Voltar para a lista de Planos de Ação',
    next: 'Próximo passo',
    explore: 'Explorar outras sugestões',
    listActivities: 'Listar atividades',
    implement: 'Quero implementar',
  },
  _modal: {
    card: 'Você chegou ao fim da trilha de recomendações! Agora, que tal explorar as outras sugestões do Boosterh?',
    title: 'Essa recomendação foi útil para você?',
    tellUs: 'Conta pra gente',
    label: 'Escreva aqui sua opinião',
    placeholder: 'Escreva uma mensagem',
    yes: 'Sim',
    no: 'Não',
    message: 'Seu comentário foi enviado!',
  },
  _advices: {
    title: 'Recomendações',
    card: 'Recomendações de como lidar com o cenário desfavorável',
    subTitle: 'Pergunta',
  },
  _actionPlans: {
    title: 'Plano de ação',
    subTitle: 'Plano de ação para lidar com a desfavorabilidade da pergunta',
    label: 'Recomendação escolhida',
  },
  _actionPlan: {
    title: 'Plano de ação',
    subTitle: 'Tarefa para lidar com a desfavorabilidade da pergunta',
    label: 'Plano de Ação escolhido',
    sectionTitle: 'Implementação da tarefa',
    pdf: 'Exportar Tarefa',
  },
  _notPossible: 'Não foi possível carregar as informações',
  _tryLoading: 'Tente novamente carregar as informações dessa página',
  _tryAgain: 'Tente novamente',
  _loadingTime: 'Isso pode levar em torno de 30 segundos.',
  _waitRecomendations: 'Estou escrevendo suas Recomendações. Aguarde mais um pouco, por favor!',
  _waitDepth: 'Estou escrevendo sua Recomendação. Aguarde mais um pouco, por favor!',
  _waitObjectives: 'Estou escrevendo seu Plano de Ação. Aguarde mais um pouco, por favor!',
  _waitActivy: 'Estou escrevendo suas Atividades. Aguarde mais um pouco, por favor!',
  _waitTask: 'Estou escrevendo sua Tarefa. Aguarde mais um pouco, por favor!',
};

const es = {
  card: `
  <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    Estoy aquí para ser tu <span class="underline decoration-2 underline-offset-4 decoration-teal-400">pareja estratégica</span>,
    ayudándote a entender los desafíos de la empresa y del equipo a partir del análisis de datos.
    Te entregaré <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">sugerencias</span>
    de acciones para que puedas planificar políticas de mejora más acertadas.
  </p>`,
  title: `Recomendaciones de Winnie`,
  subTitle: '¡Lea a continuación las recomendaciones de Boosterh para usted!',
  sectionTitle: 'Preguntas con baja favorabilidad',
  sectionTitleTwo: 'Lista de preguntas con favorabilidad inferior al 70%',
  label: 'Favorabilidad',
  createPlan: 'Crear Plan de Acción',
  seeRecomendation: 'Ver recomendación',
  emptyQuestions: 'No hay preguntas disponibles en este momento.',
  _titles: {
    favorability: 'Favorabilidad de la pregunta',
    question: 'Pregunta seleccionada',
    deep: 'Profundización',
    importance: 'Importancia',
    challenge: 'Desafíos',
    objective: 'Objetivos',
    active: 'Actividades',
    task: 'Tarea',
    preparation: 'Preparación',
    action: 'Acciones',
  },
  _data: {
    time: 'Plazo',
    conversation: 'Conversación',
  },
  _buttons: {
    backQuestion: 'Volver a las Preguntas',
    backObjective: 'Volver a lo Plano de Acción',
    backRecommendation: 'Volver a la Recomendacion',
    backRecommendations: 'Volver a las Recomendaciones',
    backDeep: 'Volver a Profundización',
    backAssignment: 'Volver a Asignaciones',
    backActivity: 'Volver a Atividades',
    backPlan: 'Volver a la lista de Planes de Acción',
    next: 'Siguiente paso',
    explore: 'Explorar otras sugerencias',
    listActivities: 'Listar actividades',
    implement: 'Quiero implementar',
  },
  _modal: {
    card: '¡Has llegado al final de la ruta de recomendaciones! Ahora, ¿qué tal explorar las otras sugerencias de Boosterh?',
    title: '¿Esta recomendación fue útil para ti?',
    tellUs: 'Cuéntanos',
    label: 'Escribe tu opinión aquí',
    placeholder: 'Escribe un mensaje',
    yes: 'Sí',
    no: 'No',
    message: '¡Tu comentario ha sido enviado!',
  },
  _advices: {
    title: 'Recomendaciones',
    card: 'Recomendaciones sobre cómo lidiar con el escenario desfavorable',
    subTitle: 'Pregunta',
  },
  _actionPlans: {
    title: 'Plan de Acción',
    subTitle: 'Plan de acción para lidiar con la desfavorabilidad de la pregunta',
    label: 'Recomendación Elegida',
  },
  _actionPlan: {
    title: 'Plan de Acción',
    subTitle: 'Tarea para lidiar con la desfavorabilidad de la pregunta',
    label: 'Plan de Acción Elegido',
    sectionTitle: 'Implementación de la Tarea',
    pdf: 'Exportar Tarea',
  },
  _notPossible: 'No se puede cargar información',
  _tryLoading: 'Intente cargar la información de esta página nuevamente.',
  _tryAgain: 'Trate de nuevo',
  _loadingTime: 'Esto puede llevar alrededor de 30 segundos.',
  _waitRecomendations: 'Esto escribiendo tus Recomendaciones. ¡Espera un poco más, por favor!',
  _waitDepth: 'Esto escribiendo tu Recomendación. ¡Espera un poco más, por favor!',
  _waitObjectives: 'Esto escribiendo tu Plano de Acción. ¡Espera un poco más, por favor!',
  _waitActivy: 'Esto escribiendo tus Actividades. ¡Espera un poco más, por favor!',
  _waitTask: 'Esto escribiendo tu Tarea. ¡Espera un poco más, por favor!',
};

const en = {
  card: `
  <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200 h-full content-center">
    I am here to be your <span class="underline decoration-2 underline-offset-4 decoration-teal-400">strategic partner</span>,
    helping you understand the challenges of the company and the team through data analysis.
    I will provide you with actionable <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">suggestions</span>
    so that you can plan more accurate improvement policies.
  </p>`,
  title: `Winnie's Recommendations`,
  subTitle: 'Read the Boosterh recommendations for you below!',
  sectionTitle: 'Questions with Low Favorability',
  sectionTitleTwo: 'Questions with Favorability below 70%',
  label: 'Favorability',
  createPlan: 'Create Action Plan',
  seeRecomendation: 'See Recommendation',
  emptyQuestions: 'There are no questions available at this time.',
  _titles: {
    favorability: 'Question favorability',
    question: 'Selected question',
    deep: 'Deepening',
    importance: 'Importance',
    challenge: 'Challenges',
    objective: 'Objectives',
    active: 'Activities',
    task: 'Task',
    preparation: 'Preparation',
    action: 'Actions',
  },
  _data: {
    time: 'Deadline',
    conversation: 'Conversation',
  },
  _buttons: {
    backQuestion: 'Back to Questions',
    backObjective: 'Back to Action Plan',
    backRecommendation: 'Back to Recommendation',
    backRecommendations: 'Back to Recommendations',
    backDeep: 'Back to Deepening',
    backAssignment: 'Back to Assignments',
    backActivity: 'Back to Activities',
    backPlan: 'Back to Action Plans list',
    next: 'Next step',
    explore: 'Explore other suggestions',
    listActivities: 'List activities',
    implement: 'I want to implement',
  },
  _modal: {
    card: 'You have reached the end of the recommendations trail! Now, how about exploring other suggestions from Boosterh?',
    title: 'Was this recommendation helpful to you?',
    tellUs: 'Tell us',
    label: 'Write your opinion here',
    placeholder: 'Write a message',
    yes: 'Yes',
    no: 'No',
    message: 'Your comment has been sent!',
  },
  _advices: {
    title: 'Recommendations',
    card: 'Recommendations on how to deal with the unfavorable scenario',
    subTitle: 'Question',
  },
  _actionPlans: {
    title: 'Action Plan',
    subTitle: 'Action plan to deal with the question’s unfavorability',
    label: 'Chosen Recommendation',
  },
  _actionPlan: {
    title: 'Action Plan',
    subTitle: 'Task to deal with the question’s unfavorability',
    label: 'Chosen Action Plan',
    sectionTitle: 'Task Implementation',
    pdf: 'Export Task',
  },
  _notPossible: 'Unable to load information',
  _tryLoading: 'Try loading the information on this page again',
  _tryAgain: 'Try again',
  _loadingTime: 'This may take around 30 seconds.',
  _waitRecomendations: 'I am writing your Recommendations. Please wait a little longer!',
  _waitDepth: 'I am writing your Recommendation. Please wait a little longer!',
  _waitObjectives: 'I am writing your Action Plan. Please wait a little longer!',
  _waitActivy: 'I am writing your Activities. Please wait a little longer!',
  _waitTask: 'I am writing your Task. Please wait a little longer!',
};

export default {
  key: 'boosterh',
  translations: {
    ptBr,
    es,
    en,
  },
};
