const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-8 text-center font-batusa mt-4">
      Efetividade da Cultura
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      A análise da
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400 font-bold">efetividade da cultura</span> organizacional combina elementos da
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400 font-bold">força</span>,
      que expressam o quanto ela é definida e conhecida, com o seu nível de
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400 font-bold">saudabilidade</span>,
      que indica o quanto a cultura é admirada e praticada no cotidiano. Esses aspectos em conjunto forcem o nível de efetividade da cultura organizacional.
    </p>`,
  title: `Efetividade da Cultura`,
  sectionTitle: `Efetividade`,
  subTitle: `O valor da Efetividade depende desses dois pontos`,
  _strength: {
    title: `Força`,
    message: `Uma cultura organizacional forte é claramente definida, declarada e compreendida por todos. Em outras palavras, o Propósito, a Visão, a Missão e os Valores são comunicados explicitamente.`,
  },
  _healthiness: {
    title: `Saudabilidade`,
    message: `Uma cultura organizacional saudável é única, singular, admirada, engajadora, praticada no dia a dia e demonstrada nos símbolos e sistemas. A saudabilidade representa a sensação de que a cultura declarada é a cultura aplicada.`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-8 text-center font-batusa mt-4">
      Efectividad de la Cultura
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      El análisis de la
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400 font-bold">efectividad de la cultura</span>
      organizacional combina elementos de
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400 font-bold">fuerza</span>,
      que expresan cuán bien está definida y conocida, con su nivel de
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400 font-bold">saudabilidad</span>,
      que indica cuánto se admira y practica la cultura en la vida cotidiana. Estos aspectos en conjunto determinan el nivel de efectividad de la cultura organizacional.
    </p>`,
  title: `Efectividad de la Cultura`,
  sectionTitle: `Efectividad`,
  subTitle: `El valor de la Efectividad depende de estos dos puntos`,
  _strength: {
    title: `Fuerza`,
    message: `Una cultura organizacional fuerte está claramente definida y comprendida por todos. En otras palabras, el Propósito, la Visión, la Misión y los Valores son comunicados explícitamente.`,
  },
  _healthiness: {
    title: `Saludabilidad`,
    message: `Una cultura organizacional saludable es única, singular, admirada, comprometida, practicada en el día a día y demostrada en los símbolos y sistemas. La saludabilidad representa la sensación de que la cultura declarada es la cultura aplicada.`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-8 text-center font-batusa mt-4">
      Cultural Effectiveness
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      The analysis of the
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400 font-bold">effectiveness</span>
      of organizational culture combines elements of
      <span class="underline decoration-2 underline-offset-4 decoration-yellow-400 font-bold">strength</span>,
      which express how well it is defined and known, with its level of
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400 font-bold">healthfulness</span>,
      which indicates how much the culture is admired and practiced in daily life. These aspects together determine the level of effectiveness of the organizational culture.
    </p>`,
  title: `Cultural Effectiveness`,
  sectionTitle: `Effectiveness`,
  subTitle: `The value of Effectiveness depends on these two points`,
  _strength: {
    title: `Strength`,
    message: `A strong organizational culture is clearly defined and understood by everyone. In other words, the Purpose, Vision, Mission, and Values are explicitly communicated.`,
  },
  _healthiness: {
    title: `Healthfulness`,
    message: `A healthy organizational culture is unique, singular, admired, engaging, practiced daily, and demonstrated through symbols and systems. Healthfulness represents the feeling that the declared culture is the culture applied.`,
  },
};

export default {
  key: 'effectiveness',
  translations: {
    ptBr,
    es,
    en,
  },
};
