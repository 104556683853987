const pagesData: Array<{ key: string; translations: object }> = [];
const pageFiles = import.meta.glob('./pages/*.ts', { eager: true });
const pageNames = Object.keys(pageFiles);

pageNames.forEach((pageName: string) => {
  const data: { key: string; translations: object } = pageFiles?.[pageName].default; // assume-se que cada arquivo .ts exporta algo como um objeto default

  if (!data) return;

  pagesData.push(data);
});

export default (locale: 'ptBr' | 'en' | 'es') => {
  const pages = {};

  pagesData.forEach((page: { key: string; translations: object }) => {
    const translation = page.translations?.[locale];

    if (!translation || !page.key) return;

    pages[`_${page.key}`] = translation;
  });

  return {
    _pages: pages,
  };
};
