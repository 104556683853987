const ptBr = {
  title: `Influenciadores`,
  sectionTitle: `Top 5 Influenciadores`,
  subTitle: `Votos`,
  vote: 'Voto',
  label: `Influenciador(a)`,
  listTitle: `Lista de Influenciadores`,
  select: `Busque um influenciador aqui`,
  insufficientData: 'Dados insuficientes',
  lowData: 'Parece que não conseguimos encontrar nenhum resultado correspondente para seus termos de pesquisa.',
  _agents: {
    title: `Agente de Mudanças`,
    subTitle: `Agentes de Mudanças`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-teal-400 font-batusa">Agentes de Mudanças</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        Um colaborador
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">"agente de mudanças"</span>
        pode ser encontrado em qualquer nível da hierarquia organizacional e é crucial para implementar novas práticas, processos, tecnologias ou culturas dentro da empresa. Agentes de mudança são proativos e inovadores, capazes de identificar necessidades de transformação e influenciar positivamente seus colegas e a liderança.
      </p>`,
  },
  _mentors: {
    title: `Mentor`,
    subTitle: `Mentores`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">Mentores</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        Um colaborador
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">"mentor"</span>
        é um indivíduo experiente e geralmente mais sênior que orienta e aconselha outros membros da empresa, especialmente aqueles que são mais jovens ou menos experientes. Ele compartilha conhecimentos, habilidades e insights para ajudar no desenvolvimento profissional e pessoal de seus mentorados.
      </p>`,
  },
  _experts: {
    title: `Expert`,
    subTitle: `Experts`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">Experts</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        Um colaborador
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">"expert"</span>
        é um profissional altamente qualificado e experiente em uma área específica de conhecimento ou domínio técnico. Com profundo entendimento e expertise, esses colaboradores são reconhecidos como autoridades ou referências dentro da empresa, fornecendo orientações técnicas em seus campos de especialização.
      </p>`,
  },
};

const es = {
  title: `Influyentes`,
  sectionTitle: `Los 5 Principales Influyentes`,
  subTitle: `Votos`,
  vote: 'Voto',
  label: `Influyente`,
  listTitle: `Lista de Influyentes`,
  select: `Busca un influyente aquí`,
  insufficientData: 'Datos insuficientes',
  lowData: 'Parece que no pudimos encontrar ningún resultado que coincida con tus términos de búsqueda.',
  _agents: {
    title: `Agente de Cambio`,
    subTitle: `Agentes de Cambio`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-teal-400 font-batusa">Agentes de Cambio</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        Un
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">"agente de cambio"</span>
        puede encontrarse en cualquier nivel de la jerarquía organizacional y es crucial para implementar nuevas prácticas, procesos, tecnologías o culturas dentro de la empresa. Los agentes de cambio son proactivos e innovadores, capaces de identificar necesidades de transformación e influir positivamente en sus colegas y en la dirección.
      </p>`,
  },
  _mentors: {
    title: `Mentor`,
    subTitle: `Mentores`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">Mentores</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        Un
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">"mentor"</span>
        es un individuo experimentado y más senior que orienta y asesora a otros miembros de la empresa, especialmente a los más jóvenes o menos experimentados. Comparte conocimientos, habilidades y perspectivas para ayudar en el desarrollo profesional y personal de sus mentees.
      </p>`,
  },
  _experts: {
    title: `Experto`,
    subTitle: `Expertos`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">Expertos</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        Un colaborador
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">"experto"</span>
        es un profesional altamente cualificado y experimentado en un área específica de conocimiento o dominio técnico. Con un profundo entendimiento y pericia, estos colaboradores son reconocidos como autoridades o referentes dentro de la empresa, proporcionando orientación técnica en sus campos de especialización.
      </p>`,
  },
};

const en = {
  title: `Influencers`,
  sectionTitle: `Top 5 Influencers`,
  subTitle: `Votes`,
  vote: 'Vote',
  label: `Influencer`,
  listTitle: `List of Influencers`,
  select: `Search for an influencer here`,
  insufficientData: 'Insufficient data',
  lowData: `It seems we couldn't find any matching results for your search terms.`,
  _agents: {
    title: `Change Agent`,
    subTitle: `Change Agents`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-teal-400 font-batusa">Change Agents</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        A
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">"change agent"</span>
        can be found at any level of the organizational hierarchy and is crucial for implementing new practices, processes, technologies, or cultures within the company. Change agents are proactive and innovative, capable of identifying transformation needs and positively influencing their colleagues and leadership.
      </p>`,
  },
  _mentors: {
    title: `Mentor`,
    subTitle: `Mentors`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-orange-400 font-batusa">Mentors</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        A
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">"mentor"</span>
        is an experienced and usually more senior individual who guides and advises other members of the company, especially those who are younger or less experienced. They share knowledge, skills, and insights to help in the professional and personal development of their mentees.
      </p>`,
  },
  _experts: {
    title: `Expert`,
    subTitle: `Experts`,
    card: `
      <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-4">
        <span class="underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa">Experts</span>
      </h3>
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left">
        An
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">"expert"</span>
        employee is a highly skilled and experienced professional in a specific area of knowledge or technical domain. With deep understanding and expertise, these employees are recognized as authorities or references within the company, providing technical guidance in their fields of specialization.
      </p>`,
  },
};

export default {
  key: 'influencers',
  translations: {
    ptBr,
    es,
    en,
  },
};
